import * as React from 'react';
import styled from 'styled-components/macro';
import { colors, strings } from '../../../constants';
import { renderPassedTime } from '../../../utils/dateUtils';

interface IIncidentHeaderProps {
  createdAt: Date;
}

export const IncidentHeader = (props: IIncidentHeaderProps) => {
  return (
    <HeaderBox>
      <ReportedDate>
        <Title>{strings.incidentDetail.header.reported}</Title>
        <Time>{renderPassedTime(props.createdAt)}</Time>
      </ReportedDate>
    </HeaderBox>
  );
};

const HeaderBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;
const ReportedDate = styled.div`
  display: flex;
  flex-flow: column nowrap;
  font-size: 12px;
  line-height: 1.17;
  letter-spacing: -0.2px;
  color: ${colors.ghostWhite};
`;
const Title = styled.span`
  opacity: 0.6;
`;
const Time = styled.span``;
