import { WallIncident } from 'incident-code-core';

interface iPoints {
  latitude: number;
  longitude: number;
}
export interface IRegion {
  lng: number;
  lat: number;
}
interface IMapParams {
  center: IRegion;
  zoom: number;
}

class MapUtils {
  INCREASE_RATE = 2;

  public getInitialRegion = (incidents: WallIncident[]): IMapParams => {
    const childrenCoords = incidents.map(this.extractCoordsFromChildren);

    return this.getRegionForCoordinates(childrenCoords);
  };

  private getRegionForCoordinates = (
    points: iPoints[],
  ): IMapParams => {
    let coord = {
      minX: points[0].latitude,
      maxX: points[0].latitude,
      maxY: points[0].longitude,
      minY: points[0].longitude,
    };

    coord = points.reduce(
      (acc, point) => ({
        minX: Math.min(acc.minX, point.latitude),
        maxX: Math.max(acc.maxX, point.latitude),
        minY: Math.min(acc.minY, point.longitude),
        maxY: Math.max(acc.maxY, point.longitude),
      }),
      { ...coord }
    );

    const latitudeDelta = coord.maxX - coord.minX;
    const longitudeDelta = coord.maxY - coord.minY;

    const center = {
      lat: (coord.minX + coord.maxX) / 2,
      lng: (coord.minY + coord.maxY) / 2,
    };

    const longitudeZoom = this.calculateZoom(longitudeDelta);
    const latitudeZoom = this.calculateZoom(latitudeDelta);
    const averageZoom = Math.floor((longitudeZoom + latitudeZoom) / 2);

    return {
      center,
      zoom: averageZoom
    };
  };

  private extractCoordsFromChildren = (incident: WallIncident) => {
    const [longitude, latitude] = incident.location!.coordinates!;
    return { longitude, latitude };
  };

  private calculateZoom = (delta: number): number => Math.round(Math.log(360 / delta) / Math.LN2)
}

export const mapUtils = new MapUtils();
