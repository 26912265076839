import {
  WallIncident,
  IncidentType,
  IncidentChat,
  IncidentEscortState,
  IncidentLocation,
  IncidentEscort
} from 'incident-code-core'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components/macro'

import { openNewWindow } from '../../utils/browser-utils'
import { escortService } from '../../services/escort-service'
import { SidePanel } from '../../components/SidePanels/TipSidePanel'
import FilterContextProvider from '../../contexts/FilterContext'
import { colors } from '../../constants'

import {
  Header,
  Loader,
  ActionModal,
  ShareForm,
  NotifyUser,
  CloseEscort,
  AddNote,
  IncidentDetail,
  Chat
} from '../../components/'

import { Actions } from './components/Actions'
import { SubHeader, Alert, Legend } from './components'

import VideoChat from './components/VideoChat/VideoChat'
import { GeneratePublicCodeCheckbox } from '../../components/GeneratePublicCodeCheckbox'
import { Wrapper } from '../../components/ShareForm/styles'

interface IncidentParams {
  id: string
}

export const Escort = () => {
  const params = useParams<IncidentParams>()
  const [incident, setIncident] = useState<WallIncident>({ type: IncidentType.Normal, videos: [] })
  const [chat, setChat] = useState<IncidentChat>()
  const [chatChannel, setChatChannel] = useState<any>(false)
  const [status, setStatus] = useState<IncidentEscortState>(null)
  const [escort, setEscort] = useState<IncidentEscort>(null)
  const [isClosed, setClosed] = useState<boolean>(false)
  const [escortLocations, setEscortLocations] = useState<IncidentLocation>()
  const [notifyModal, setOpenNotifyModal] = useState(false)
  const [closeModal, setCloseModal] = useState(false)
  const [addNoteModal, setAddNoteModal] = useState(false)
  const [shareModal, setShareModal] = useState(false)

  const toggleNotifyModal = React.useCallback(() => setOpenNotifyModal(!notifyModal), [notifyModal])
  const toggleCloseModal = React.useCallback(() => setCloseModal(!closeModal), [closeModal])
  const toggleAddNoteModal = React.useCallback(() => setAddNoteModal(!addNoteModal), [addNoteModal])
  const toggleShareModal = React.useCallback(() => setShareModal(!shareModal), [shareModal])
  const openMediasLink = React.useCallback(
    () => openNewWindow(`/escort/${incident.id}/compositions`),
    [incident.id]
  )


  const getIncident = async (id: string) => {
    const response = await escortService.getIncidentById(id)
    const incident = response.data
    if (incident.isResolved) {
      setClosed(true)
    }
    setIncident(incident)
  }

  const updateIncident = (update: any) => {
    setIncident({ ...incident, ...update })
  }

  const getIncidentChat = async (id: string) => {
    const result = await escortService.getIncidentChat(id)
    setChat(result)
  }

  const getEscort = async (id: string) => {
    const response = await escortService.getEscort(id)

    setEscort(response.data)
    setStatus(response.data.state)
  }

  const getEscortLocation = async (id: string) => {
    const result = await escortService.getEscortLocation(id)
    setEscortLocations(result)
  }

  const handleStatus = (status: IncidentEscortState) => {
    setStatus(status)
  }

  const fetchData = async () => {
    try {
      await Promise.all([
        getIncident(params.id),
        getIncidentChat(params.id),
        getEscort(params.id),
        getEscortLocation(params.id)
      ])
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [params.id])

  useEffect(() => {
    const intervalId = setInterval(fetchData, 7000)

    if (isClosed && (escort?.isCompositionReady || escort?.hasComposition)) {
      clearInterval(intervalId)
    }
    return () => clearInterval(intervalId)
  }, [params.id, isClosed, escort])

  useEffect(() => {
    document.body.style.overflow = 'scroll'
  })

  const { activeOrgUser } = incident as any;
  const loading = !incident.id || !!activeOrgUser

  if (loading)
    return (
      <Loader message={activeOrgUser && `This incident is being handled by: ${activeOrgUser}`} />
    )


  return (
    <EscortContainer>
      <Header />
      <SubHeader incident={incident} status={status} showNotificationForm={toggleNotifyModal} />
      <RootContainer>
        <IncidentsContainer>
          <Container>
            <div>
              <IncidentContainer>
                <IncidentDetail incident={incident} />
                {incident && !!chatChannel && <VideoChat isClosed={isClosed} incident={incident} />}
              </IncidentContainer>
              <Alert status={status} id={params.id} handleStatus={handleStatus} />
            </div>
            <Actions
              escort={escort}
              isClosed={isClosed}
              showCloseEscortOverlay={toggleCloseModal}
              showGenerateLinkOverlay={toggleShareModal}
              showAddNoteOverlay={toggleAddNoteModal}
              showMediasLink={openMediasLink}
            />
          </Container>
        </IncidentsContainer>
        <Side>
          <SidePanel
            type={incident.type}
            home={false}
            incidents={[incident]}
            showContent={true}
            chat={chat}
            escortLocations={escortLocations}
          >
            <Legend isEscort={true} />
          </SidePanel>
          <Chat isClosed={isClosed} incident={incident} setChatChannel={setChatChannel} />
        </Side>
      </RootContainer>
      <ActionModal open={notifyModal}>
        <FilterContextProvider>
          <NotifyUser onHideForm={toggleNotifyModal} />
        </FilterContextProvider>
      </ActionModal>
      <ActionModal open={closeModal}>
        <CloseEscort onHideForm={toggleCloseModal} incidentId={incident.id.toString()} />
      </ActionModal>
      <ActionModal open={addNoteModal}>
        <AddNote onHideForm={toggleAddNoteModal} incidentId={incident.id.toString()} />
      </ActionModal>
      <ActionModal open={shareModal}>
        <Wrapper>
          <GeneratePublicCodeCheckbox
            modalHandler={toggleShareModal}
            incident={incident}
            handleData={updateIncident}
          />

          <ShareForm
            onHideForm={toggleShareModal}
            incidentId={incident.id.toString()}
            inverted={true}
          />
        </Wrapper>
      </ActionModal>
    </EscortContainer>
  )
}

Escort.defaultProps = {
  incident: {}
}

const EscortContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const RootContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${colors.black};
`

const IncidentsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 50%;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
`

const IncidentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 1.5em;
`

const Side = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
