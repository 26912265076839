import * as React from "react"
import styled from "styled-components/macro"
import { AppUser } from "incident-code-core"
import { colors, DetailTypes, strings } from "../../constants"
import { getCreatedAtString } from "../../utils/dateUtils"
import { USPhoneFormat } from "../../utils/formatPhoneNumbers"

type MockVideo = {
  [key: string]: string | undefined
}

type MockVideoList = MockVideo[]

type Data = AppUser | string | Date | MockVideoList

interface IDetailProps {
  data: Data
  type: DetailTypes
}

const DETAIL_TITLES = {
  [DetailTypes.CREATOR]: strings.incidentDetail.detailTitles.creator,
  [DetailTypes.CONTACT]: strings.incidentDetail.detailTitles.contact,
  [DetailTypes.CATEGORY]: strings.incidentDetail.detailTitles.incidentType,
  [DetailTypes.ADDRESS]: strings.incidentDetail.detailTitles.address,
  [DetailTypes.DATE]: strings.incidentDetail.detailTitles.date
}

export class Detail extends React.Component<IDetailProps> {
  render() {
    const { type } = this.props
    return (
      <Container>
        <Title>{DETAIL_TITLES[type]}</Title>
        {this.renderContent(type)}
      </Container>
    )
  }

  private renderContent = (type: DetailTypes): React.ReactNode => {
    const contentByType = {
      [DetailTypes.CREATOR]: this.getCreatorData,
      [DetailTypes.CONTACT]: this.getContactData,
      [DetailTypes.CATEGORY]: () => <DetailInfo>{this.props.data}</DetailInfo>,
      [DetailTypes.ADDRESS]: () => <DetailInfo>{this.props.data}</DetailInfo>,
      [DetailTypes.DATE]: () => (
        <DetailInfo>{getCreatedAtString(this.props.data as Date)}</DetailInfo>
      )
    }

    return contentByType[type]()
  }

  private getCreatorData = (): React.ReactNode => {
    const { firstName, lastName } = this.props.data as AppUser
    return <DetailInfo>{`${firstName} ${lastName}`}</DetailInfo>
  }

  private getContactData = (): React.ReactNode => {
    const { phone } = this.props.data as AppUser
    return <DetailInfo>{USPhoneFormat(phone)}</DetailInfo>
  }
}

const Container = styled.div`
  width: 90%;
  line-height: 1.2em;
  margin-bottom: 0.40em;
`

const Title = styled.h3`
  color: ${colors.midnight};
  font-size: 14px;
  line-height: 1;
  margin-bottom: 0.3em;
`

const DetailInfo = styled.span`
  color: ${colors.ghostWhite};
  font-size: 14px;
`
