import React from 'react'
import styled from "styled-components/macro"

import { colors } from "../../../../constants";

interface IAlertPanicEscort {}

export const AlertPanicEscort: React.FC<IAlertPanicEscort> = () => (
  <RootContainer>
    <Attention>Attention</Attention>
    <Message>User has enabled panic mode</Message>
  </RootContainer>
);

const RootContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${colors.darkRed};
  padding: 20px
  height: 107px
`
const Attention = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #D21515;
  padding: 7px;
  color: ${colors.white}
  font-size: 16px
  margin-right: 25px
  padding: 30px
`
const Message = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${colors.white}
  font-size: 16px
`
