import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '..'
import adapter  from './adapter'

const {
  selectAll: selectAllNotificatons,
  selectById: selectNotificationById,
  selectTotal: selectTotalNotificatons,
  selectEntities: selectNotificationEntities,
  selectIds: selectNotificationIds
} = adapter.getSelectors((state: RootState) => state.notifications)

const selectIsThereNotifications = createSelector(
  (state: RootState) => selectAllNotificatons(state),
  notifications => !notifications.every(notification => notification.consumed)
)

const selectNotificationsForToasts = createSelector(
  (state: RootState) => selectAllNotificatons(state),
  notifications => notifications.filter(notification => notification.recievedFromFirebase && !notification.consumed)
)

export default {
  selectAllNotificatons,
  selectIsThereNotifications,
  selectNotificationsForToasts,
}