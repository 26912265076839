import { FormValues } from './interfaces';

export const handleSubmit = ({
  message
}: FormValues,
{
  props, setSubmitting, resetForm
}: any) => {
  props.onSend(message)
  setSubmitting(false)
  resetForm({})
}