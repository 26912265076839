import 'react-toastify/dist/ReactToastify.css'
import { Provider } from 'react-redux'

import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { Login } from '../Login'
import { Home } from '../Home'

import { AppContainer } from './AppContainer'

import { PrivateRoute } from '../../components/hocs'
import { ROUTES } from '../../constants'
import { Incident } from '../Incident'
import { Escort } from '../Escort'
import { EscortComposition } from '../EscortCompositions'
import { Report } from '../Report'

import './App.css'
import store from '../../store'

export class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <AppContainer>
            <Route exact path={ROUTES.login} component={Login} />
            <Route exact path={ROUTES.incident} component={Incident} />
            <Route exact path={ROUTES.escort} component={Escort} />
            <Route exact path={ROUTES.report} component={Report} />
            <Route exact path={ROUTES.escortCompositions} component={EscortComposition} />
            <PrivateRoute path={ROUTES.home} component={Home} />
          </AppContainer>
          {/* <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover
          /> */}
        </BrowserRouter>
      </Provider>
    )
  }
}
