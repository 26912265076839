import React, { Fragment, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import styled from 'styled-components/macro'

import { ChatReport, IncidentType, IncidentPassive } from 'incident-code-core'

import { incidentService } from '../../services/incident-service'
import { ShareForm } from '../../components/ShareForm'
import { appSetting } from '../../services/app-setting'
import { Incident } from './interfaces'
import { Detail } from './Detail'
import { Uploaded } from './Uploaded'
import { Location } from './Location'
import { Histories } from './Histories'
import { AuditLog } from './AuditLog'
import { Wrapper } from '../../components/ShareForm/styles'
import { escortService } from '../../services/escort-service'

type TParams = {
  id: string
}

export const Report = ({ match }: RouteComponentProps<TParams>) => {
  const [escortLocations, setEscortLocations] = useState<any>()
  const [incident, setIncident] = useState<Incident>()
  const [chat, setChat] = useState<ChatReport>()
  const [log, setLog] = useState<IncidentPassive>()
  const [showSharingForm, setShowSharingForm] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      const [{ data }, chat] = await Promise.all([
        incidentService.getReportIncidentById(match.params.id),
        incidentService.getReportChat(match.params.id)
      ])
      setIncident(data as Incident)
      setChat(chat)
      if (!!data) {
        const { type } = data
        if ([IncidentType.PassiveEscort, IncidentType.Escort].includes(type)) {
          if (type === IncidentType.PassiveEscort) {
            const [escortLocations, auditLog] = await Promise.all([
              escortService.getEscortLocation(match.params.id),
              incidentService.getAuditLog(match.params.id)
            ])
            setEscortLocations(escortLocations)
            setLog(auditLog)
          } else {
            const escortLocations = await escortService.getEscortLocation(match.params.id)
            setEscortLocations(escortLocations)
          }
        }
      }
    })()
  }, [match.params.id])

  const toggleSharing = () => {
    setShowSharingForm(!showSharingForm)
  }
  return (
    <Container>
      <Nav className="d-flex pr-4 pl-4 pt-3 pb-3">
        <span className="text-light" onClick={() => window.close()}>
          Close
        </span>
      </Nav>
      <div className="d-flex justify-content-center">
        <ReportContent className="mt-4">
          {appSetting.currentUser &&
            appSetting.currentUser.organizations &&
            appSetting.currentUser.organizations.length > 0 && (
              <ShareButton
                className="position-fixed text-light pt-3 pr-4 pb-3 pl-4 mr-4"
                onClick={() => toggleSharing()}
              >
                Share Report
              </ShareButton>
            )}
          {showSharingForm && (
            <Overlay className="position-fixed mr-4">
              <Wrapper>
                <ShareForm
                  onHideForm={() => toggleSharing()}
                  incidentId={match.params.id}
                  isReport={true}
                  inverted={false}
                />
              </Wrapper>
            </Overlay>
          )}
          {incident && (
            <Fragment>
              <Detail incident={incident} />
              <Location incident={incident} escortLocations={escortLocations} />
              <Uploaded incident={incident} />
              <Histories chat={chat} />
              {log && <AuditLog passiveLog={log} />}
            </Fragment>
          )}
        </ReportContent>
      </div>
    </Container>
  )
}

const Container = styled.div`
  background: #fff;
`

const Nav = styled.div`
  background: #16181e;
`

const ReportContent = styled.div`
  width: 70%;
  max-width: 900px;
  position: relative;
`

const ShareButton = styled.div`
  z-index: 999;
  background: linear-gradient(279deg, #503bff, #a11de7);
  right: 0;
  cursor: pointer;
`

const Overlay = styled.div`
  display: flex;
  align-items: center;
  width: 328px;
  right: 0;
  margin-top: 60px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(25, 27, 35, 0.94);
  z-index: 1000;
`
