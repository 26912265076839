import { Reducer, useEffect, useReducer } from "react"
import { IncidentVideo } from "incident-code-core"
import produce, { Draft } from "immer"
import { VideoPlayer } from "./video-player-service"

interface IncidentState {
  readonly service: VideoPlayer
  readonly video: IncidentVideo
}

enum ActionType {
  SetIncidentVideo = 'SET_INCIDENT_VIDEO',
  NextVideo = 'NEXT_VIDEO',
  ChangeCurrentVideo = 'CHANGE_CURRENT_VIDEO',
}

type Action =
  { type: ActionType.SetIncidentVideo, payload: IncidentState }
  | { type: ActionType.NextVideo, payload: IncidentVideo }
  | { type: ActionType.ChangeCurrentVideo, payload: IncidentVideo }

const initialState: IncidentState = {
  service: {} as VideoPlayer,
  video: {}
}

const reducer: Reducer<IncidentState, Action> = produce((draft: Draft<IncidentState>, action: Action) => {
  switch (action.type) {
    case ActionType.SetIncidentVideo:
      draft.service = action.payload.service
      draft.video = action.payload.video
      break
    case ActionType.NextVideo:
      draft.video = action.payload
      break
    case ActionType.ChangeCurrentVideo:
      draft.video = action.payload
      break
  }
})

export function useVideoService(videoList: IncidentVideo[]) {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const setIncidentVideos = () => {
      const service = new VideoPlayer(videoList)
      dispatch({
        type: ActionType.SetIncidentVideo,
        payload: {
          service,
          video: service.currentVideo,
        }
      })
    }

    setIncidentVideos()
  }, [videoList])


  const playNext = () => {
    state.service.next()
    dispatch({
      type: ActionType.NextVideo,
      payload: state.service.currentVideo,
    })
  }
  const changeCurrentVideo = (id: string): void => {
    state.service.setCurrentVideoById(id)
    dispatch({
      type: ActionType.ChangeCurrentVideo,
      payload: state.service.currentVideo,
    })
  }
  return {
    state,
    playNext,
    changeCurrentVideo
  }
}
