import React, { useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { images, ROUTES, strings } from '../../constants'
import { userService } from '../../services/user-service'
import { notificationService } from '../../services/notification-service'
import NotificationIcon from '../NotificationIcon/NotificationIcon'
import { Container, Logo, StyledDropdown, AccountArea } from './styles'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, resetAction } from '../../store'
import { toast } from 'react-toastify'

interface IHeaderProps extends RouteComponentProps {}

export const Header: React.FC<IHeaderProps> = ({ history }) => {
  const dispatch = useDispatch()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const notificationToken = useSelector((state: RootState) => state.firebase.token)
  const toggle = () => setDropdownOpen(prevState => !prevState)

  const onLogout = async () => {
    try {
      if (notificationToken) {
        await notificationService.unsubscribe(notificationToken)
      }
    } catch (error) {
      console.log('an error occured when unsubscribe notification user')
    }
    userService.logout()
    history.push(ROUTES.login)
    toast.dismiss()
    dispatch(resetAction())
  }

  return (
    <Container>
      <Logo src={images.logo} />
      <AccountArea>
        <NotificationIcon />
        <StyledDropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle caret color="none">
            {strings.header.myAccount}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem className="logoutButton" onClick={onLogout}>
              {strings.header.logOut}
            </DropdownItem>
          </DropdownMenu>
        </StyledDropdown>
      </AccountArea>
    </Container>
  )
}

export const HeaderWithRouter = withRouter(Header)
