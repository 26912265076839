import React from 'react';
import { Organization } from "incident-code-core"
import { orgService } from '../services/orgs-service';

interface ContextProps {
  organizations: Organization[];
  setOrganizations: Function;
  selectedOrganization: Organization;
  setOrganization: Function
}

// We define our type for the context properties right here
interface Props {
  children: React.ReactNode | React.ReactNode[];
};

export const FilterContext = React.createContext<ContextProps>({
  organizations: [],
  setOrganizations: () => {},
  selectedOrganization: null,
  setOrganization: () => {}
});

const FilterContextProvider: React.FC<Props> = (props) => {
  const [organizations, setOrganizations] = React.useState<Organization[]>([]);
  const [selectedOrganization, setOrganization] = React.useState<Organization>(null);

  React.useEffect(() => { getOrganizations() }, [])

  const getOrganizations = async () => {
    const result = await orgService.getOrganizations();
    setOrganizations(result)
  }

  const value = { organizations, setOrganizations, selectedOrganization, setOrganization };

  return (
    <FilterContext.Provider value={value}>
      {props.children}
    </FilterContext.Provider>
  );
}

export default FilterContextProvider;