import * as React from 'react';
import styled from 'styled-components/macro';
import { colors } from '../../../constants';


interface IButtonProps {
  isGradient: boolean;
  text: string;
  onClick(): void;
}
type StyledButtonProps = { isGradient: boolean };

export const ButtonComponent = (props: IButtonProps) => {
  const { isGradient, text } = props;
  return (
    <Button isGradient={isGradient} onClick={props.onClick}>{text}</Button>
  );
};

const Button = styled.button`
  background: ${(props: StyledButtonProps) =>
    props.isGradient ? colors.linearGradient : colors.background};
  color: ${colors.white};
  flex: 1;
  padding: 7px 10px;
  outline: none;
  border: none;
  font-size: 15px;
  letter-spacing: -0.2px;
  text-align: center;
  margin: 0 5px;
  &:first-child {
    margin-left: 0;
    margin-right: 5px;
  };
  &:last-child {
    margin-left: 5px;
    margin-right: 0;
  }
`;
