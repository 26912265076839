import React from 'react';
import styled from "styled-components/macro"

import IOtherVideoProps from './interfaces'
import Preview from './Preview'

const SingleVideo: React.FC<IOtherVideoProps> = ({
  videos,
  currentVideo,
  onPreviewClick
}) => (
  <VideoContainer>
    {videos.map((video, index) => {
      const { thumbnailUrl, id, uploadId } = video
      const { id: currentId } = currentVideo
      const onClick = () => onPreviewClick(id)
      return (
        <Preview
          fullwidth={false}
          key={index}
          thumbnailUrl={thumbnailUrl}
          currentId={currentId}
          id={id}
          index={index}
          uploadId={uploadId}
          handleClick={onClick}
        />
      )
    })}
  </VideoContainer>
)

const VideoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  min-height: 23.5vh;
`

export default SingleVideo;
