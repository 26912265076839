import * as React from 'react';
import styled from 'styled-components/macro';
import { RouterProps } from 'react-router';

import { LoginInputs, LoginButtons, ErrorMessage } from './components';

import { userService } from '../../services/user-service';
import { appSetting } from '../../services/app-setting';
import { colors, strings, ROUTES } from '../../constants';
import logo from '../../assets/images/logo.png';
import { AxiosError } from "axios";

interface ILoginState {
  login: string;
  password: string;
  error?: string;
}

export class Login extends React.Component<RouterProps, ILoginState> {
  state: ILoginState = {
    login: '',
    password: '',
  };

  componentDidMount() {
    if (appSetting.isLoggedIn) {
      this.props.history.push(ROUTES.home);
    }
  }

  render() {
    const { login, password, error } = this.state;

    return (
      <Container>
        <LoginBox>
          <LogoContainer>
            <Logo src={logo} />
          </LogoContainer>
          <LoginInputs
            login={login}
            password={password}
            changeLogin={this.changeLogin}
            changePassword={this.changePassword}
          />

          {error && <ErrorMessage message={error} isError={true} />}

          <LoginButtons
            onLogin={this.onLogin}
            resetPassword={this.resetPassword}
          />
        </LoginBox>
      </Container>
    );
  }

  private changeLogin = (login: string): void => {
    this.setState({
      login,
    });
  };

  private changePassword = (password: string): void => {
    this.setState({
      password,
    });
  };

  private onLogin = () => {
    const { login, password } = this.state;

    if (!login || !password) {
      this.setState({ error: strings.loginErrorMessage});
      return;
    }

    userService
      .login(login, password)
      .then(() => {
        this.props.history.replace(ROUTES.home);
      })
      .catch((error: AxiosError) => {
        let message: string;
        if (error.response.data.code === 'user_no_belong_organization') {
          message = strings.loginScreen.blockToUse;
        } else {
          message = error.response.data.message || strings.genericApiError;
        }

        this.setState({ error: message});
      });
  };

  private resetPassword = (): void => {
    this.setState({
      login: '',
      password: '',
    });
  };
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.background};
  overflow-y: scroll;
`;

const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 425px;
  align-items: stretch;
  justify-content: flex-start;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Logo = styled.img`
  max-width: 241px;
  max-height: 66px;
`;
