import React from "react"
import styled from "styled-components/macro"
import {
  IncidentEscortState
} from "incident-code-core"

import { colors, fonts, strings } from "../../../../constants"

interface IStatusProps {
  status: IncidentEscortState,
}

export const Status: React.FC<IStatusProps> = ({ status }) => {
  let state = '';
  switch (status) {
    case IncidentEscortState.Active:
      state = strings.escortDetail.active;
      break;

    case IncidentEscortState.Panic:
      state = strings.escortDetail.panic;
      break;

    case IncidentEscortState.Background:
      state = strings.escortDetail.background;
      break;

    case IncidentEscortState.Unknown:
      state = strings.escortDetail.unknown;
      break;

    case IncidentEscortState.Closed:
      state = strings.escortDetail.closed;
      break;

    case IncidentEscortState.Safe:
      state = strings.escortDetail.safe;
      break;

    case IncidentEscortState.Expired:
      state = strings.escortDetail.expired;
      break;

    case IncidentEscortState.Canceled:
      state = strings.escortDetail.canceled;
      break;

    default:
      state = strings.escortDetail.pending;
      break;
  }
  return (
    <Container><Title><TitleText>{state} Virtual escort</TitleText></Title></Container>
  )
}


const Container = styled.div`
  flex-grow: 1;
  align-items: flex-start;
`
const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`
const TitleText = styled.h2`
  font-family: ${fonts.defaultFont};
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: -0.2px;
  text-align: center;
  color: ${colors.ghostWhite};
`