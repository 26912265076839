import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  createSelector
} from '@reduxjs/toolkit'
import { RootState } from '..'

const adapter = createEntityAdapter({

  sortComparer: (a: any, b: any) =>
    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
})

const initialState = adapter.getInitialState({
  init: false,
  loading: false,
  error: null,
  pagination: {
    loadingNext: false,
    count: 0,
    index: 0,
    size: 0,
    hasMore: false
  },
  filters: {
    type: null,
    orgId: null,
    isResolved: false
  }
})

const loadingFailed = (state: typeof initialState, { payload }: PayloadAction<any>) => {
  state.loading = false
  state.pagination.loadingNext = false
  state.init = true
  state.error = payload
}

const slice = createSlice({
  name: 'incidents',
  initialState,
  reducers: {
    getIncidents: (state, action: PayloadAction<'loadNext'>) => {
      action.payload ? (state.pagination.loadingNext = true) : (state.loading = true)
    },

    getIncidentsSuccess: (state, action: PayloadAction<any>) => {
      adapter.upsertMany(state, action.payload.data)
      state.pagination = action.payload.pagination
      state.pagination.loadingNext = false
      state.loading = false
      state.init = true
    },
    getIncidentsFailed: loadingFailed,
    addIncident: (state, action) => {
      adapter.upsertOne(state, action.payload)
    },
    updateIncident: adapter.updateOne,
    setFilters: (state, action) => {
      state.loading = true
      state.filters = { ...state.filters, ...action.payload }
    }
  }
})

export const incidentActions = slice.actions

export const selectIncidentState = (state: RootState) => state.incidents

export const {
  selectAll: selectAllIncidents,
  selectById: selectIncidentById,
  selectTotal: selectTotalIncidents,
  selectEntities: selectIncidentEntities,
  selectIds: selectIncidentIds
} = adapter.getSelectors(selectIncidentState)

export const selectFilteredIncidents = createSelector(
  (state: RootState) => selectAllIncidents(state),
  (state: RootState) => state.incidents.filters,
  (incidents, { isResolved, orgId, type }) => {
    let filteredIncidents = incidents.filter(incident => {
      if (!isResolved && incident.type === 'passiveEscort') {
        return (
          !!incident.escortState &&
          ['active', 'pending', 'panic'].includes(incident.escortState.state)
        )
      }
      return incident.isResolved === isResolved
    })

    filteredIncidents = filteredIncidents.filter(incident => {
      if (type === 'escort') {
        return ['passiveEscort', 'escort'].includes(incident.type)
      } else {
        return !type || incident.type === type
      }
    })

    if (orgId) {
      filteredIncidents = filteredIncidents.filter(({ organization }) => orgId === organization)
    }

    return filteredIncidents
  }
)

const reducer = slice.reducer

export default reducer
