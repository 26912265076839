import React from "react"
import styled from "styled-components/macro"
import { IncidentType } from "incident-code-core"

import { strings, colors } from "../../constants"

interface IStyleProps {
  active: boolean
}

interface IFilterProps {
  incidentType: IncidentType
  handleIncidentType(type: IncidentType): void
}

export const Navbar: React.FC<IFilterProps> = ({
  incidentType, handleIncidentType
}) => (
  <Nav>
    <li>
      <TabsButton
        onClick={() => handleIncidentType(null)}
        active={incidentType === null}
      >
        <Title>All</Title>
      </TabsButton>
    </li>
    <li>
      <TabsButton
        onClick={() => handleIncidentType(IncidentType.Normal)}
        active={incidentType === IncidentType.Normal}
      >
        <Title>{strings.tips}</Title>
      </TabsButton>
    </li>
    <li>
      <TabsButton
        onClick={() => handleIncidentType(IncidentType.Escort)}
        active={incidentType === IncidentType.Escort}
      >
        <Title>{strings.escorts}</Title>
      </TabsButton>
    </li>
  </Nav>
);

const Nav = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 1rem 0;
`

const Title = styled.div`
  font-size: 18px;
`

const TabsButton = styled.div<IStyleProps>`
  color: ${props => props.active ? colors.lightGray : colors.midGray1};
  cursor: pointer;
  padding: 10px 20px;
`