import React from "react"

import { strings } from "../../../constants"
import { ButtonComponent } from "./Button"

interface ICloseButtonProps {
  isResolved: boolean,
  showCloseIncidentOverlay(): void
}

export const CloseButton: React.FC<ICloseButtonProps> = ({
  isResolved, showCloseIncidentOverlay
}) => {
  if (isResolved === true) { return null}

  return (
    <ButtonComponent
      text={strings.incidentDetail.buttons.resolve}
      isGradient={true}
      onClick={showCloseIncidentOverlay}
    />
  )
}