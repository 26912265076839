import * as React from "react"
import { Id } from "incident-code-core"


interface Props {
  children: React.ReactNode | React.ReactNode[];
};

export interface IVisibilityContext {
  scrollTo: string
  setScroll(id: Id): void
  resetScroll(): void
}

export const VisibilityContext = React.createContext<IVisibilityContext>({
  scrollTo: "",
  setScroll: () => {},
  resetScroll: () => {},
})

const VisibilityContextProvider: React.FC<Props> = (props) => {
  const [scrollTo, setScrollTo] = React.useState<string>("");
  const setScroll = (id: string) => {setScrollTo(id)}
  const resetScroll = () => setScroll("")

  const value = {
    scrollTo,
    setScroll,
    resetScroll,
  };

  return (
    <VisibilityContext.Provider value={value}>
      {props.children}
    </VisibilityContext.Provider>
  );
}

export default VisibilityContextProvider;