import React from "react"
import styled from "styled-components/macro"
import {
  IncidentChat,
  IncidentLocation,
  IncidentType,
  WallIncident
} from "incident-code-core"

import { Map } from "./components/Map"

interface MapProps {
  type: IncidentType;
  shownId?: null | string;
  home: boolean;
  incidents: WallIncident[];
  showContent?: boolean;
  chat?: IncidentChat;
  escortLocations?: IncidentLocation;
  children?: any;
}

type StyledBoxMessageProps = { home: Boolean };

export const SidePanel: React.FC<MapProps> = ({
  incidents,
  shownId,
  home,
  showContent,
  type,
  chat,
  escortLocations,
  children
}) => (
  <Container home={home}>
    <Map
      type={type}
      incidents={incidents}
      shownId={shownId}
      chat={chat}
      escortLocations={escortLocations}
      showMessages={showContent}
      showVideos={showContent}
    />
    {children}
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: ${(props: StyledBoxMessageProps) => props.home ? "100%" : "50%"};
  width: ${(props: StyledBoxMessageProps) => props.home ? "40%" : "100%"};
`;
