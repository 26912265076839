import { AxiosResponse } from "axios"
import { ApiService } from "./api-service"
import {
  Organization,
  OrganizationArea
} from "incident-code-core"
import { appSetting } from "./app-setting"

interface IOrgService {
  getOrganizationInfoById(id: string): void
}

class OrgService extends ApiService implements IOrgService {

  public getOrganizationInfoById = async (id: string): Promise<AxiosResponse<Organization>> => {
    const paths = ["organizations", id, "info"]
    const response = await this.get<Organization>(paths)
    return {
      ...response,
      data: {
        ...response.data,
      }
    }
  }

  public async getOrganizations(): Promise<Organization[]> {
    const result = await this.get(["organizations"]);
    const userOrganizations = appSetting.currentUser.organizations;
    const organizations = result.data.data.filter((org: Organization) => userOrganizations
      .filter(o => o === org.id).length > 0)

    return organizations;
  }

  public getAreas = async (id: string): Promise<OrganizationArea[]> => {
    const paths = ["organizations", id, "area"]
    const response = await this.get(paths)
    return response.data;
  }

}

export const orgService = new OrgService()
