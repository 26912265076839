const svgWithData = (data?: string) => {
  const svg = `<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="42" height="42" rx="13" fill="#444964"/>
  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Proxima Nova" font-size="12" font-weight="600" letter-spacing="0em"><tspan x="18.7207" y="25.232">${data ||
    ''}</tspan></text>
  </svg>`

  return encodeURIComponent(svg)
}

export const customMarker = (text?: string): string => {
  return `data:image/svg+xml;charset=UTF-8,${svgWithData(text)}`
}

export const circleMarker = (): string => {
  return `data:image/svg+xml;charset=UTF-8,<svg class="bi bi-circle text-success" width="3em" height="3em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" d="M10 17a7 7 0 100-14 7 7 0 000 14zm0 1a8 8 0 100-16 8 8 0 000 16z" clip-rule="evenodd"/>
  </svg>`
}

export const squareMarker = (): string => {
  return `data:image/svg+xml;charset=UTF-8,<svg class="bi bi-square" width="3em" height="3em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" d="M16 3H4a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1V4a1 1 0 00-1-1zM4 2a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2H4z" clip-rule="evenodd"/>
  </svg>`
}
