import { Dict, WallIncident, AccessToken } from "incident-code-core"

import { ApiService } from "./api-service"

const KeyTwilioAccessToken = "TwilioAccessToken"
const ExpireOffset = 8400000 // a 2 hour 20 m

export class TwilioAuthService extends ApiService {
  accessToken: Dict<AccessToken> = {}
  initialized = false

  async getToken(incident?: WallIncident, force?: boolean): Promise<string> {
    const organizationId = incident?.organization?.toString() || null
    if (!this.initialized) {
      this.initialized = true
      this.loadAccessToken()
    }

    if (organizationId) {
      return this.getTokenWithOrganization(organizationId, force)
    }

    return this.getTokenWithoutOrganization(force)
  }


  private async getTokenWithOrganization(organizationId: string, force?: boolean) {
    if (organizationId && this.isValid()) {
      return this.accessToken[organizationId].access_token
    }

    // Twilio token will expire in 24 hours, the app need to update it every day.
    if (force) {
      const response = await this.post(["wall", "twilio", "auth"])
      this.setAccessToken(response.data, organizationId)

      this.initialized = true
      if (this.accessToken && organizationId) {
        return this.accessToken[organizationId].access_token
      } else if (this.accessToken && !organizationId) {
        const organization = this.getFirstOrganization(this.accessToken)
        return this.accessToken[organization].access_token
      } else {
        throw Error("Twilio token not found.");
      }
    }
  }


  /**
   * Used on Incidents Component when initialize chat without any organizations
   */
  private async getTokenWithoutOrganization(force?: boolean) {
    // TODO: Improve it because always get the first organization
    try {
      if (this.accessToken) {
        const organization = this.getFirstOrganization(this.accessToken)
        return this.getTokenWithOrganization(organization, force)
      }
      return this.getTokenWithOrganization(null, force)
    } catch (e) {
      console.error(e)
    }
  }

  private loadAccessToken(): void {
    const value = localStorage.getItem(KeyTwilioAccessToken)
    if (value !== null) {

      Object.assign(this.accessToken, JSON.parse(value))
    }
  }

  private setAccessToken(token: any, organizationId?: string): void {
    if (token !== null) {

      if (organizationId && !token[organizationId]?.expires_at) {

        token.expires_at = Date.now() + token[organizationId].expires_in * 1000 - ExpireOffset
      } else {
        const organization = this.getFirstOrganization(token)
        token.expires_at = Date.now() + token[organization].expires_in * 1000 - ExpireOffset
      }

      Object.assign(this.accessToken, token)
      localStorage.setItem(KeyTwilioAccessToken, JSON.stringify(this.accessToken))
    }
  }

  private isValid() {
    const { expires_at } = this.accessToken;
    return expires_at > Date.now()
  }

  private getFirstOrganization(accessToken: any) {
    return Object.keys(accessToken)[0]
  }
}

export const twilioAuthService = new TwilioAuthService()
