import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'appData',
  initialState: {
    timestamp: Date.now()
  },
  reducers: {
    setTimestamp: (state, action) => {
      state.timestamp = action.payload
    }
  }
})

const reducer = slice.reducer

export const appDataActions = slice.actions

export default reducer