import React from 'react';
import { FormikProps, ErrorMessage } from "formik"

import { strings } from "../../constants"
import { AddNoteProps, FormValues } from './interfaces'
import {
  Wrapper,
  Form,
  Textarea,
  Button,
  Label,
  H3,
  RedMessage,
  CancelButton
} from './styles'


const CloseIncidentForm: React.FC<AddNoteProps & FormikProps<FormValues>> = ({
  values, status, handleChange, handleBlur, handleSubmit, isSubmitting, onHideForm
}) => (
  <Wrapper>
    <Form onSubmit={handleSubmit}>
      <H3>Do you really want to close the incident?</H3>
      <Label>Outcome Notes</Label>
      <Textarea
        name="comment"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.comment}
        placeholder={strings.incidentDetail.sendMessageForm.placeholder}
        disabled={isSubmitting}
      />
      <ErrorMessage component={RedMessage} name="comment" />
      {status && status.message && <RedMessage>{status.message}</RedMessage>}
      <Button type="submit" disabled={isSubmitting} gradient={true}>
        {strings.incidentDetail.resolvedIncident}
      </Button>
    </Form>
    <CancelButton onClick={onHideForm} gradient={false}>
      {strings.incidentDetail.buttons.cancel}
    </CancelButton>
  </Wrapper>
);

export default CloseIncidentForm;
