import React from 'react';
import styled from "styled-components/macro"
import {
  WallHistoryRequest,
  HistoryType,
  ChatActionType,
  IncidentEscortState
} from "incident-code-core"
import { historyService } from "../../../../services/history-service"
import { escortService } from "../../../../services/escort-service"
import { colors } from "../../../../constants";

interface IActivateEscortProps {
  id: string,
  handleStatus(status: IncidentEscortState): void
}

export const ActivateEscort: React.FC<IActivateEscortProps> = ({ id, handleStatus }) => {
  const handleUpdateStatus = (updateStatus: IncidentEscortState) => {
    escortService.updateEscortState(id, updateStatus).then(() => handleStatus(updateStatus))
  }

  const onCloseDetail = () => {
    const payload: WallHistoryRequest = {
      incidentId: `${id}`,
      type: HistoryType.Chat,
      action: ChatActionType.Closed
    }
    historyService.create(payload).then(() => window.close())
  }

  return (
    <RootContainer>
      <Title>
        The escort request has not yet been accepted by any
        administrator, do you wish to accept?
      </Title>
      <Actions>
        <Button onClick={() => handleUpdateStatus(IncidentEscortState.Active)}>Accept</Button>
        <Button onClick={onCloseDetail}>Exit</Button>
      </Actions>
    </RootContainer>
  )
}


const RootContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${colors.white};
  padding: 30px;
  margin-bottom: 20px;
`
const Title = styled.div`
  font-size: 25px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: ${colors.black};
  padding: 40px;
`
const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  justify-content: space-around;
`
const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 20px;
`