import { Config } from "incident-code-core"
import { ApiService } from "./api-service"
import { AxiosResponse } from "axios";

class ConfigApiService extends ApiService {
  config?: Config = new Config()
  basePaths = ["configs"]

  async init(): Promise<void> {
    const response = await this.getConfig()

    Object.assign(this.config, response.data)
  }

  getConfig(): Promise<AxiosResponse<Config>> {
    return this.get([], null)
  }
}

export const configService = new ConfigApiService()
