import { withFormik } from 'formik'
import * as Yup from 'yup';
import { IncidentEscortState } from "incident-code-core"

import { escortService } from "../../services/escort-service"
import { AddNoteProps, FormValues } from './interfaces'
import CloseEscortForm from './Form'

const closeEscortSchema = Yup.object().shape({
  comment: Yup.string().trim().min(1).required(),
});

export const CloseEscort = withFormik<AddNoteProps, FormValues>({
  mapPropsToValues: () => ({ comment: "" }),
  validationSchema: closeEscortSchema,
  handleSubmit({ comment }: FormValues, { props, setSubmitting, resetForm, setStatus }) {
    escortService.updateEscortState(props.incidentId, IncidentEscortState.Closed, comment).then(() => {
      props.onHideForm();
      resetForm({})
    }).catch((error) => {
      setStatus({message: error.response?.data?.message })
    })

    setSubmitting(false)
  }
})(CloseEscortForm);

export default CloseEscort;
