import React from 'react'
import styled from "styled-components/macro"
import {
  WallIncident
} from 'incident-code-core';

import { Map } from "../../components/SidePanels/TipSidePanel/components/Map"

interface IProps {
  incident: WallIncident
  escortLocations?: []
}

export const Location: React.FC<IProps> = ({ incident, escortLocations }) => (
  <div>
    <div className="pt-4 pb-2 font-weight-bold">
      Incident Location
    </div>
    <MapContainer>
      <Map
        type={incident.type}
        incidents={[incident]}
        showMessages={true}
        showVideos={true}
        escortLocations={escortLocations}
        zoom={7}

      />
    </MapContainer>
  </div>
)

const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  width: 100%;
`
