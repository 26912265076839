import { createSlice, createAction } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'firebase',
  initialState: {
    token: null,
    subscribed: false,
    error: null
  },
  reducers: {
    getToken: state => {
      state.token = null
      state.subscribed = false
    },
    getTokenSuccess: (state, { payload }) => {
      state.token = payload
      state.error = null
    },
    getTokenFailed: (state, { payload }) => {
      state.error = payload
      state.subscribed = false
    },
    subscribe: state => {
      state.subscribed = false
    },
    subscribeSuccess: state => {
      state.subscribed = true
    },
    subscribeFailed: (state, { payload }) => {
      state.subscribed = false
      state.error = payload
    }
  }
})

const reducer = slice.reducer

export const recievedFirebaseMessage = createAction<any>('firebase/recieveFirebaseMessage')

export const firebaseActions = slice.actions

export default reducer
