import { put, takeEvery, call } from 'redux-saga/effects'
import { axios } from '../axiosService'
import { Action } from '@reduxjs/toolkit'
import { notificationActions } from './reducer'
import { normalizeNotificationHistory } from './utils'

const {
  getNotifications,
  getNotificationsFailed,
  getNotificationsSuccess,
  markNotificationAsRead,
  markNotificationAsReadFailed,
  markNotificationAsReadSuccess,
  markNotificationsForIncidentAsRead,
  markNotificationsForIncidentAsReadFailed,
  markNotificationsForIncidentAsReadSuccess
} = notificationActions

export default function* notificationSaga() {
  yield takeEvery(getNotifications.type, getNotificationHistory)
  yield takeEvery(markNotificationAsRead.type, markNotificationAsReadSaga)
  yield takeEvery(markNotificationsForIncidentAsRead.type, markNotificationsForIncidentAsReadSaga)
}

function* getNotificationHistory() {
  try {
    const resp = yield call(() => axios.get('wall/notifications', { params: { limit: 10 } }))
    yield put(getNotificationsSuccess(resp.data.map(normalizeNotificationHistory)))
  } catch (error) {
    console.error(error)
    yield put(getNotificationsFailed(error))
  }
}

function* markNotificationAsReadSaga(action: Action) {
  if (markNotificationAsRead.match(action)) {
    const { id } = action.payload
    try {
      yield call(() => axios.patch(`wall/notifications/${id}`))
      yield put(markNotificationAsReadSuccess({ id }))
    } catch (error) {
      console.error(error)
      yield put(markNotificationAsReadFailed({ id, error }))
    }
  }
}

function* markNotificationsForIncidentAsReadSaga(action: Action) {
  if (markNotificationsForIncidentAsRead.match(action)) {
    const { incidentId } = action.payload
    try {
      yield call(() => axios.patch(`wall/notifications/incident/${incidentId}`))
      yield put(markNotificationsForIncidentAsReadSuccess({ incidentId }))
    } catch (error) {
      console.error(error)
      yield put(markNotificationsForIncidentAsReadFailed({ incidentId, error }))
    }
  }
}
