import { AppUser, WallIncident } from 'incident-code-core'
import React from 'react'
import ReactPlayer from 'react-player'
import { Element } from 'react-scroll'
import styled from 'styled-components/macro'
import { colors, images } from '../../../../constants'
import { SCROLL_CHILD_CLASS } from '../../../../constants/scroll'
import { useVideoService } from '../../../../services/video-service-hook'
import { Info } from './Info'
import { Title } from './Title'
import { openNewWindow } from '../../../../utils/browser-utils'

interface IIncidentProps {
  incident: WallIncident
  title: string
  organization: string
  id: string
  isPlaying: boolean
  onClick?: CallableFunction
}

export const IncidentCard = (props: IIncidentProps) => {
  const { incident, title, organization, id, isPlaying } = props
  const { state, playNext } = useVideoService(incident.videos)

  const url = state.video && state.video.url ? state.video.url : ''
  const createdBy = incident.createdBy
    ? incident.createdBy
    : { firstName: 'unknown', lastName: 'unknown', phone: 'unknown' }
  const incidentHasOneVideo = incident.videos.length === 1

  return (
    <NavContainer
      onClick={() => {
        openNewWindow(`incident/${incident.id}`)
        props?.onClick()
      }}
      className={SCROLL_CHILD_CLASS}
    >
      {url ? (
        <StyledReactPlayer
          url={url}
          playing={isPlaying}
          loop={incidentHasOneVideo}
          onEnded={playNext}
          muted={true}
          width="100%"
          height="390px"
          style={{
            borderRadius: 6,
            overflow: 'hidden'
          }}
        />
      ) : (
        <DefaultImage>
          <img src={images.defaultImage} alt="No video available" />
        </DefaultImage>
      )}
      <IncidentBox name={incident.id.toString()}>
        <OverlayGradient />
        <Title
          title={title}
          index={id}
          organization={organization}
          createdAt={incident.createdAt}
        />
        <Info
          isUnresolved={incident.isNewIncident}
          createdBy={createdBy as AppUser}
          address={incident.address}
          createdAt={incident.createdAt}
        />
      </IncidentBox>
    </NavContainer>
  )
}

const NavContainer = styled.div`
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-basis: 215px;
  height: 390px;
  flex-grow: 1;
  max-width: 280px;
  text-decoration: none;
  margin-bottom: 30px;
  margin-right: 30px;
  &:hover {
    cursor: pointer;
  }
`

const StyledReactPlayer = styled(ReactPlayer)`
  video {
    object-fit: cover;
  }
`

const DefaultImage = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const IncidentBox = styled(Element)`
  border-radius: 5px;
  display: flex;
  flex-flow: column nowrap;
  height: 390px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`

const OverlayGradient = styled.div`
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  background: -moz-linear-gradient(
    top,
    ${colors.overlayGradientColors[0]} 0%,
    ${colors.overlayGradientColors[1]} 18%,
    ${colors.overlayGradientColors[2]} 50%,
    ${colors.overlayGradientColors[3]} 100%
  );
  background: -webkit-linear-gradient(
    top,
    ${colors.overlayGradientColors[0]} 0%,
    ${colors.overlayGradientColors[1]} 18%,
    ${colors.overlayGradientColors[2]} 50%,
    ${colors.overlayGradientColors[3]} 100%
  );
  background: linear-gradient(
    to bottom,
    ${colors.overlayGradientColors[0]} 0%,
    ${colors.overlayGradientColors[1]} 18%,
    ${colors.overlayGradientColors[2]} 50%,
    ${colors.overlayGradientColors[3]} 100%
  );
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
