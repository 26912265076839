import { withFormik } from 'formik'
import * as Yup from 'yup'
import { IncidentWallAlertRequest } from 'incident-code-core'

import { incidentService } from '../../services/incident-service'
import { SendAlertProps, FormValues } from './interfaces'
import { NotifyForm } from './Form'

const chatSchema = Yup.object().shape({
  organization: Yup.string().required(),
  title: Yup.string().required(),
  body: Yup.string().required(),
  sendToOrganizationUsers: Yup.boolean(),
  sendOnlyOrganizationArea: Yup.boolean(),
});

export const handleSubmit = ({
  organization, title, body, sendOnlyOrganizationArea
}: FormValues,
{
  props, setSubmitting, resetForm
}: any) => {
  const payload: IncidentWallAlertRequest = {
    organization,
    title,
    body,
    immediateZone: sendOnlyOrganizationArea
  }

  if (process.env.NODE_ENV === "development") {
    console.log("You are running on developer mode. Don't dispatch messages to users", payload)
  } else {
    incidentService.sendMessage(payload)
  }

  setSubmitting(false)
  props.onHideForm();
  resetForm({})
}

export const NotifyUser = withFormik<SendAlertProps, FormValues>({
  mapPropsToValues: () => ({
    organization: '',
    title: '',
    body: '',
    sendToOrganizationUsers: false,
    sendOnlyOrganizationArea: false,
  }),
  validationSchema: chatSchema,
  handleSubmit: handleSubmit,
})(NotifyForm);
