import {
  WallIncident,
} from "incident-code-core"
import React from "react"
import styled from "styled-components/macro"
import {
  Participant,
} from 'twilio-video'
import { VideoEventHandler, videoChatService } from "../../../../services/video-chat-service"

interface Props {
  incident: WallIncident
  isClosed: boolean
}

interface State {
  participants: Participant[],
}

class VideoChat extends React.Component<Props, State> implements VideoEventHandler {
  private audioRef: React.RefObject<HTMLMediaElement>;
  private videoRef: React.RefObject<HTMLVideoElement>;

  constructor(props: any) {
    super(props)
    this.state = {
      participants: [],
    }
    this.audioRef = React.createRef()
    this.videoRef = React.createRef()
  }

  async componentDidMount(): Promise<void> {
    const { incident, isClosed } = this.props;
    if (!incident.isResolved || !isClosed) {
      await videoChatService.init(incident, this)
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: State): Promise<void> {
    const { isClosed } = this.props
    if (prevProps.isClosed !== isClosed && isClosed === true) {
      videoChatService.close();
    }
  }

  componentWillUnmount() {
    if (videoChatService.room && videoChatService.room.state === 'connected') {
      videoChatService.close();
    }
  }

  render(): React.ReactNode {

    return (
      <Container>
        <Video ref={this.videoRef} controls={true} autoPlay={true} />
        <PlayerAudio>
          <audio ref={this.audioRef} controls={true} autoPlay={true} />
        </PlayerAudio>
      </Container>
    )
  }

  onParticipantDisconnected = (participant: Participant) => {
    this.setState(prevState => ({
      participants: prevState.participants.filter(p => p !== participant)
    }))
  }

  onParticipantConnected = (participant: Participant) => {
    this.setState(prevState => ({
      participants: [...prevState.participants, participant]
    }))
  }

  onTrackAdded = (track: any, participant: Participant) => {
    console.log(participant.identity + ' added track: ' + track);
  }

  onTrackRemoved = (track: any, participant: Participant) => {
    console.log(participant.identity + ' removed track: ' + track);
  }

  onTrackSubscribed = (track: any) => {
    if (track.kind === 'audio') {
      track.attach(this.audioRef.current);
    } else if (track.kind === "video") {
      track.attach(this.videoRef.current);
    }
  }

  onTrackUnsubscribed = (track: any) => {
    if (track.kind === 'audio') {
      track.detach(this.audioRef.current);
    } else if (track.kind === "video") {
      track.detach(this.videoRef.current);
    }
  }
}

export default React.memo(VideoChat)

const Video = styled.video`
  height: 400px
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
`

const PlayerAudio = styled.div`
  display: flex;
  height: 5em;
  margin-bottom: 20px;
  align-items: center;
`