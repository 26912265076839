import { ApiService } from "./api-service"
import { appSetting } from "./app-setting"

interface IUserService {
  login(username: string, password: string): Promise<void>
  logout(): void
}

class UserService extends ApiService implements IUserService {
  public login = async (username: string, password: string): Promise<void> => {
    const url = ["wall", "login"]
    const payload = { username, password }
    const response = await this.post(url, payload)
    appSetting.setAccessToken(response.data)
  }

  public logout = () => {
    appSetting.clearStorage()
  }
}

export const userService = new UserService()
