import { Id, IncidentVideo } from "incident-code-core"

type IPlayNext = () => void

export class VideoPlayer {
  private currentIndex: number = 0
  private readonly listVideos: IncidentVideo[]

  constructor(videoList: IncidentVideo[]) {
    this.listVideos = videoList
  }

  public setCurrentVideoById = (id: string) => {
    const videoIndex = this.listVideos.findIndex(video => video.id === id)

    if (videoIndex || videoIndex === 0) {
      this.currentIndex = videoIndex
    }
  }

  // CONTROLS
  public next = () => {
    const isLastVideo = this.currentIndex === this.listVideos.length - 1
    if (isLastVideo) {
      this.currentIndex = 0
    } else {
      this.currentIndex = this.currentIndex + 1
    }
  }

  //GETTERS
  get currentVideo(): IncidentVideo {
    return this.listVideos[this.currentIndex]
  }
  get allVideos(): IncidentVideo[] {
    return this.listVideos
  }
  public getCurrentVideoID = (): Id => {
    return this.currentVideo.id
  }
  public getCurrentIndex = (): number => {
    return this.currentIndex
  }
}
