import "core-js"
import "whatwg-fetch"

import React from "react"
import ReactDOM from "react-dom"
import { App } from "./scenes/App/App"
import * as serviceWorker from "./serviceWorker"
import "bootstrap/dist/css/bootstrap.min.css"
import "./style.css"

ReactDOM.render(<App />, document.getElementById("root"))
serviceWorker.unregister()
