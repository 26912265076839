import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { notificationSelectors } from '../../store'
import { toast } from 'react-toastify'
import NotificationComponent from './NotificationComponent'

// this hook exists to help resolve circular dependencies
// by triggering our toasts from inside a react component instead of
// redux we no longer have to import a react component into redux
// this helps keep our entity logic and presentation logic modularized

export function useNotificationToastHook() {
  const [activeToasts, setActiveToasts] = useState<string[]>([])
  const notifications = useSelector(notificationSelectors.selectNotificationsForToasts)

  useEffect(() => {
    const notificationIds = notifications.map(notification => notification.id)
    activeToasts.forEach(toastId => {
      if (!notificationIds.includes(toastId)) {
        toast.dismiss(toastId)
      }
    })

    notifications.forEach(notification => {
      toast(<NotificationComponent notification={notification} popover={false} />, {
        autoClose: false,
        closeButton: false,
        draggable: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        type: notification.type === 'panic' ? toast.TYPE.ERROR : toast.TYPE.DEFAULT,
        toastId: notification.id
      })

      setActiveToasts(notificationIds)
    })
  }, [notifications])

  return activeToasts
}
