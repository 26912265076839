import React from "react"
import { Switch } from 'react-router-dom'
import styled from "styled-components/macro"
import { colors } from "../../constants"
import { Header } from "../../components"
import { Incidents as AllIncidents } from '../AllIncidents';
import { ROUTES } from '../../constants';
import { PrivateRoute } from '../../components/hocs';
import FilterContextProvider from '../../contexts/FilterContext';


export const Home = () => {

  return (
    <FilterContextProvider>
      <Container>
        <IncidentsContainer>
          <Header />
          <Switch>
            <PrivateRoute exact path={ROUTES.home} component={AllIncidents} />
          </Switch>
        </IncidentsContainer>
      </Container>
    </FilterContextProvider>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100vh;
  background-color: ${colors.black};
`

const IncidentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
