import React from 'react'
import { useParams } from "react-router"
import { WallIncident, IncidentType, MediaLink } from "incident-code-core"
import styled from "styled-components/macro"
import { Container, Col, Row } from "reactstrap"
import ReactPlayer from 'react-player'

import { colors, images } from '../../constants'
import {
  Header,
  Loader,
} from "../../components/"

import { useMediaLinkService } from "../../hooks/mediaLinkHook"
import { escortService } from "../../services/escort-service"
import { Detail } from "../Report/Detail"
import { Incident } from "../Report/interfaces"
import Preview from "./Compositions/Preview"

interface IncidentParams {
  id: string;
}

interface IProps {}

export const EscortComposition: React.FC<IProps> = () => {
  const params = useParams<IncidentParams>()
  const [incident, setIncident] = React.useState<WallIncident>({ type: IncidentType.Normal, videos: [] })
  const [videos, setVideos] = React.useState<MediaLink[]>([])
  const [isPlaying, setPlaying] = React.useState<boolean>(true);
  const togglePlay = (): void => setPlaying(!isPlaying);
  const { state, changeCurrentVideo } = useMediaLinkService(videos)
  const incidentHasOneVideo = React.useMemo(() => {
    return !Boolean(state.service.allVideos) || state.service.allVideos.length === 1
  }, [state.service.allVideos])

  const getIncident = async (id: string) => {
    const response = await  escortService.getIncidentById(id);
    setIncident(response.data);
  }

  const fetchMedia = async (id: string) => {
    const response = await  escortService.getMediaLink(id);
    setVideos(response.data);
  }
  React.useEffect(() => {
    fetchMedia(params.id)
    getIncident(params.id)
  }, [params.id])

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      fetchMedia(params.id)
    }, 180000);
    return () => clearInterval(intervalId);
  }, [params.id])

  if (videos.length === 0) { return <Loader />}

  return (
    <EscortContainer>
      <Header />
      <RootContainer>
        <Container>
          <Row className="mt-4 mb-4">
            <Detail color="#fff" incident={incident as Incident} />
          </Row>
          <Row className="mt-4 mb-4">
            <Col className="text-center" >
              {state.video &&
                <ReactPlayer
                  url={state.video.link}
                  controls={true}
                  muted={false}
                  loop={incidentHasOneVideo}
                  volume={100}
                  playing={isPlaying}
                  onEnded={() => togglePlay()}
                  config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                  onContextMenu={(ev: any) => ev.preventDefault()}
                />
              }
            </Col>
          </Row>
          <Row className="mt-4 mb-4">
            {(state.service.allVideos || []).map((video, index) => {
              const { sid, kind, type } = video
              const { sid: currentId } = state.video
              let thumbnail = images.video
              const onClick = () => changeCurrentVideo(sid)
              if (kind && kind === "audio") {
                thumbnail = images.audio
              }
              return (
                <Col md={3} className="mb-4" key={video.sid.toString()}>
                  <Preview
                    fullwidth={true}
                    key={index}
                    thumbnailUrl={thumbnail}
                    currentId={currentId}
                    id={sid}
                    index={index}
                    handleClick={onClick}
                    kind={kind}
                    type={type}
                  />
                </Col>
              )
            })}
          </Row>
        </Container>
      </RootContainer>
    </EscortContainer>
  );
}


const EscortContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const RootContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${colors.black};
`
