import React from "react"

import { strings } from "../../constants"
import { Button } from "./styles"

interface INotificationButtonProps {
  showNotificationForm(): void
}

export const NotificationButton: React.FC<INotificationButtonProps> = ({
  showNotificationForm
}) => (
  <Button onClick={showNotificationForm}>
    {strings.incidentDetail.buttons.notifyUsers}
  </Button>
)
