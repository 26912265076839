import React from 'react'
import { IncidentEscortState } from 'incident-code-core';

import { AlertPanicEscort } from './Panic';
import { ActivateEscort } from './Activate';

interface IAlertProps {
  status: string,
  id?: string,
  handleStatus?: (status: IncidentEscortState) => void
}

export const Alert: React.FC<IAlertProps> = ({ status, id, handleStatus}) => {
  switch (status) {
    case IncidentEscortState.Pending:
      return <ActivateEscort id={id} handleStatus={handleStatus} />

    case IncidentEscortState.Panic:
      return <AlertPanicEscort />

    default:
      return null;
  }
}

