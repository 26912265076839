import styled from "styled-components/macro";
import { colors, fonts } from "../../constants"

export const Button = styled.button`
  background: linear-gradient(104deg, #503bff, #4632ec);
  color: ${colors.ghostWhite};
  border: none;
  font-size: 16px;
  font-family: ${fonts.defaultFont};
  padding: 20px 25px;
  border-radius: 5px;
`