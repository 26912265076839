import React from 'react'
import {
  MediaLink, WallIncident
} from 'incident-code-core'
import styled from "styled-components/macro"
import ReactPlayer from "react-player"
import { Col, Row } from "reactstrap"

import { escortService } from "../../services/escort-service"
import { PlayButton } from "../Incident/components/Player/components/PlayButton"

interface IProps {
  incident: WallIncident
}

export const Compositions: React.FC<IProps> = ({ incident }) => {
  const [compositions, setCompositions] = React.useState<MediaLink[]>([])
  const [playingVideo, setPlayingVideo] = React.useState<string>();

  const togglePlay = (id: string) => {
    if(id === playingVideo) {
      setPlayingVideo(null);
      return;
    }
    setPlayingVideo(id);
  }

  React.useEffect(() => {
    (async () => {
      const response = await escortService.getMediaLink(incident.id as string)
      setCompositions(response.data)
    })();

  }, [incident])

  return (
    <Row>
      {compositions.map(v =>
        <Col md={3} className="mb-4" key={v.sid.toString()}>
          <PlayerContainer
            onClick={() => togglePlay(v.sid.toString())}
          >
            <ReactPlayer
              url={v.link}
              width="100%"
              height="100%"
              className="bg-light position-absolute pt-2 pb-2"
              style={{
                top: 0,
                left: 0,
                border: '1px solid #ddd'}}
              playing={playingVideo === v.sid.toString()}
              onEnded={() => togglePlay(v.sid.toString())}
            />
            <PlayButton
              isPlaying={playingVideo === v.sid.toString()}
              sideSize={55}
              onClick={() => togglePlay(v.sid.toString())}
            />
          </PlayerContainer>
          <div>
            <p>{v.kind} {v.type}</p>
          </div>
        </Col>
      )}
    </Row>
  )
}

const PlayerContainer = styled.div`
  position: relative;
  padding-top: 80%;
  cursor: pointer;
`
