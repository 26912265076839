import { Dict, WallIncident, IncidentEscort } from "incident-code-core"
import {
  connect,
  Room,
  ConnectOptions,
  Participant,
  TwilioError
} from 'twilio-video';

import { twilioAuthService } from "./twilio-auth-service"

export interface VideoEventHandler {
  onParticipantDisconnected(participant: Participant): void
  onParticipantConnected(participant: Participant): void
  onTrackAdded(track: any, participant: Participant): void
  onTrackRemoved(track: any, participant: Participant): void
  onTrackSubscribed(track: any): void
  onTrackUnsubscribed(track: any): void
}

export class VideoChatService {
  incident: WallIncident
  escort: IncidentEscort
  participants: Dict<string> = {}
  eventHandler: VideoEventHandler
  room: Room
  options: ConnectOptions

  async init(
    incident: WallIncident,
    eventHandler: VideoEventHandler
  ) {
    this.incident = incident

    const token = await twilioAuthService.getToken(this.incident, true)
    if (token === null) return
    this.options = {
      audio: false,
      video: false,
      networkQuality: true,
      name: this.incident.id as string,
      logLevel: "off"
    };
    try {
      this.room = await this.connectToRoom(token)
      this.eventHandler = eventHandler
      this.room.on('participantConnected', this.onParticipantConnected);
      this.room.on('participantDisconnected', this.onParticipantDisconnected);
      this.room.on('trackAdded', this.onTrackAdded);
      this.room.on('trackRemoved', this.onTrackRemoved)
      this.room.on('trackSubscribed',this.onTrackSubscribed)
      this.room.on('trackUnsubscribed', this.onTrackUnsubscribed)
      this.room.on('disconnected', this.tearDown)
      this.room.on('reconnected', this.reconnected)
      this.room.on('reconnecting', this.reconnecting)

      this.room.participants.forEach(this.onParticipantConnected);
    } catch (error) {
    }
  }

  private connectToRoom = async (token: string) => await connect(token, this.options);

  public getLocalParticipant = (): Participant => { return this.room.localParticipant }

  public getRoomIsRecord = (): boolean => { return this.room.isRecording }

  public getRoomIsState = (): string => { return this.room.state }

  private updateBandwidthConstraints = (
    maxAudioBitrate?: number | null,
    maxVideoBitrate?: number | null
  ) => {
    this.room.localParticipant.setParameters({
      maxAudioBitrate: maxAudioBitrate,
      maxVideoBitrate: maxVideoBitrate
    });
  }

  public close = () => {
    if (this.room) {
      this.room.disconnect();
      this.room = null;
      return;
    }
  }

  private refreshToken = async () => {
    const token = await twilioAuthService.getToken(this.incident, true)
    this.room = await this.connectToRoom(token)
  }

  private tearDown = (room: Room, error: TwilioError) => {
    switch (error.code) {
      case 20104:
        this.refreshToken();
        break;

      default:
        this.close()
        break;
    }
  }

  private reconnected = () => console.log('Reconnected to the Room!');

  private reconnecting = (error: TwilioError) => {
    if (error.code === 53001) {
      console.log('Reconnecting your signaling connection!', error.message);
    } else if (error.code === 53405) {
      console.log('Reconnecting your media connection!', error.message);
    }
  }

  private onParticipantDisconnected = (participant: Participant) => {
    this.eventHandler.onParticipantDisconnected(participant)
  }

  private onParticipantConnected = (participant: Participant) => {
    this.eventHandler.onParticipantConnected(participant)
  }

  private onTrackAdded = (track: any, participant: Participant) => {
    this.eventHandler.onTrackAdded(track, participant)
  }

  private onTrackRemoved = (track: any, participant: Participant) =>  {
    this.eventHandler.onTrackRemoved(track, participant)
  }

  private onTrackSubscribed = (track: any) => {
    this.eventHandler.onTrackSubscribed(track)
  }

  private onTrackUnsubscribed = (track: any) => {
    this.eventHandler.onTrackUnsubscribed(track)
  }
}

export const videoChatService = new VideoChatService()
