import React from 'react'
import styled from "styled-components/macro"
import { Id } from "incident-code-core"

import { OverlayGradient, PlayButton } from "../../../components"

interface IPreviewProps {
  kind?: string
  type: string
  thumbnailUrl?: string
  fullwidth: boolean
  currentId: Id
  id: Id
  index: number
  handleClick(): void
}


const Preview: React.FC<IPreviewProps> = ({
  kind,
  type,
  fullwidth,
  thumbnailUrl,
  currentId,
  id,
  index,
  handleClick
}) => (
  <Container fullwidth={fullwidth}>
    <VideoPreview thumbnail={thumbnailUrl} key={index}>
      {currentId === id || (
        <>
          <PlayButton isPlaying={false} sideSize={45} onClick={handleClick} />
          <OverlayGradient />
        </>
      )}
    </VideoPreview>
    <p>{kind} {type}</p>
  </Container>
)

interface IVideoPreviewProps {
  thumbnail?: string
}

interface IContainerProps {
  fullwidth: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props: IContainerProps) => props.fullwidth ? '100%' : '30%'};
`

const VideoPreview = styled.div`
  position: relative;
  max-width: 10em;
  height: 20vh;
  background: ${(props: IVideoPreviewProps) => props.thumbnail ? `url(${props.thumbnail})` : "grey"};
  background-position: center center;
  background-size: cover;
  margin-right: 10px;
`

export default Preview;
