import React from 'react';

import { Button } from './styles';


interface IButtonProps {
  className?: string;
  isGradient: boolean;
  text: string;
  onClick(): void;
}

export const ButtonComponent: React.FC<IButtonProps> = ({ className, isGradient, text, onClick }) => (
  <Button className={className} isGradient={isGradient} onClick={onClick}>{text}</Button>
);

