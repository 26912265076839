import styled from "styled-components/macro"

import { colors } from "../../constants"

interface IStyleProps {
  active: boolean
}

export const TabsContainer = styled.div`
  padding: 0 20px 0 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`

export const TabsButton = styled.div<IStyleProps>`
  padding: 10px 20px;
  cursor: pointer;
  color: ${colors.white};
  border-bottom: 3px solid ${props => props.active ? colors.primary : 'transparent'};
`