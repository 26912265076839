import React from 'react'
import { WallIncident, AppUser, IncidentType } from 'incident-code-core'
import styled from 'styled-components/macro'

import { configService } from '../../services/config-service'
import { DetailTypes, fonts, colors, strings } from '../../constants'
import { UserIcon } from '../../components'
import { Detail } from './Detail'
import { openNewWindow } from '../../utils/browser-utils'
import { Comment } from './Comment'

interface IIncidentDetailProps {
  incident: WallIncident
}

export const IncidentDetail: React.FC<IIncidentDetailProps> = ({ incident }) => {
  const { type, createdAt, createdBy, address, id, comment, category: incidentCategory } = incident
  let category = null

  // TODO: Clarify: what should we show if Incident hasn't createdBy(user)
  const userInfo: AppUser = createdBy
    ? (createdBy as AppUser)
    : {
        firstName: 'unknown',
        lastName: 'unknown',
        phone: 'unknown'
      }
  if (type === IncidentType.Normal && incidentCategory) {
    category = configService.config!.getIncidentCategory(incidentCategory!).display
  } else if (type === IncidentType.Escort) {
    category = 'Escort'
  }

  return (
    <Container>
      <UserIcon user={userInfo} height={250} />
      <Detail data={userInfo} type={DetailTypes.CREATOR} />
      <Detail data={userInfo} type={DetailTypes.CONTACT} />
      <Detail data={address || ''} type={DetailTypes.ADDRESS} />
      <Detail data={createdAt || new Date()} type={DetailTypes.DATE} />
      {category !== null && <Detail data={category} type={DetailTypes.CATEGORY} />}
      <Comment comment={comment} />
      <SeeReporterButton onClick={() => openNewWindow(`/report/${id}`)}>
        {strings.incidentDetail.buttons.seeReport}
      </SeeReporterButton>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column wrap;
  align-items: normal;
  width: 90%;
  // max-height: 400px;
  margin-right: 1.5em;
`

const SeeReporterButton = styled.button`
  display: flex;
  align-items: flex-end;
  background: linear-gradient(279deg, #503bff, #a11de7);
  color: ${colors.ghostWhite};
  border: none;
  font-size: 15px;
  font-family: ${fonts.defaultFont};
  padding: 8px;
  justify-content: center;
  margin-bottom: 0.75em;
`
