import React from 'react';

import { images } from '../../constants';
import { Button } from './styles';

interface IPlayButton {
  isPlaying: boolean;
  sideSize: number;
  onClick(): void;
}

export const PlayButton: React.FC<IPlayButton> = ({ isPlaying, sideSize, onClick }) => {
  const image = isPlaying ? images.ic_pause : images.ic_play;
  return (
    <Button
      sideSize={sideSize}
      onClick={onClick}
      isPlaying={isPlaying}
      className="player-btn"
    >
      <img src={image} alt="control" />
    </Button>
  );
};
