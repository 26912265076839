import { takeLatest, select, put, call, take, takeEvery } from 'redux-saga/effects'
import firebase from 'firebase/app'
import 'firebase/messaging'
import { firebaseActions, recievedFirebaseMessage } from '.'
import initFirebase from '../../utils/firebaseUtils'
import { notificationService } from '../../services/notification-service'
import { eventChannel } from 'redux-saga'
import { toast } from 'react-toastify'
import NotificationComponent from '../../components/NotificationIcon/NotificationComponent'
import React from 'react'
import { NotificationType } from 'incident-code-core'
import { incidentActions } from '../incidents'
import { notificationActions } from '../notifications'
import { normalizeFirebaseNotification } from '../notifications/utils'
import { get } from 'lodash'

export default function* firebaseRootSaga() {
  yield takeLatest(firebaseActions.subscribe, subscribe)
  yield takeLatest(firebaseActions.getToken, getToken)
  yield takeLatest(firebaseActions.subscribeSuccess, recieveNotificationHandler)
}

function* getToken() {
  try {
    const token = yield call(initFirebase)

    yield put(firebaseActions.getTokenSuccess(token))
  } catch (error) {
    console.error(error)
    yield put(firebaseActions.getTokenFailed(error))
  }
}

function* subscribe() {
  let token = yield select(state => state.firebase.token)
  if (!token) {
    yield put(firebaseActions.getToken())
    const action = yield take(firebaseActions.getTokenSuccess)
    token = action.payload
  }
  try {
    yield call(() => notificationService.subscribe(token))
    yield put(firebaseActions.subscribeSuccess())
  } catch (error) {
    yield put(firebaseActions.subscribeFailed(error))
  }
}

async function createFirebaseMessagingChannel() {
  try {
    const serviceWorkerRegistrations = await navigator.serviceWorker.getRegistrations()

    await Promise.all(serviceWorkerRegistrations.map(async reg => await reg.update()))

    const channel = eventChannel(emitter => {
      const messaging = firebase.messaging()

      navigator.serviceWorker.onmessage = message => {
        const { data } = message
        data.data = get(data, 'data', null) ||  get(data, 'firebaseMessaging.payload.data' ,null)
        if (data.data) {
          emitter({ ...data })
        }
      }

      const unsub = messaging.onMessage(message => {
        emitter(message)
      })

      return unsub
    })

    return channel
  } catch (error) {
    console.error(error)
    throw new Error('Service Worker Registration Failed - please try a different browser')
  }
}

function* notificationEventDispatcher(notification: any) {
  // yield put(recievedFirebaseMessage(notification))

  // if (notification.data.type !== 'SILENT') {
  //   yield call(() =>
  //     toast(
  //       React.createElement(NotificationComponent, {
  //         notification,
  //         popover: false
  //       }),
  //       {
  //         autoClose: false,
  //         closeButton: false,
  //         draggable: false,
  //         position: toast.POSITION.BOTTOM_RIGHT,
  //         type: notification?.data?.type === 'panic' ? toast.TYPE.ERROR : toast.TYPE.DEFAULT,
  //         toastId: notification.data?.incidentId.toString()
  //       }
  //     )
  //   )
  // }

  const incident = notification.data.incident && JSON.parse(notification.data.incident)

  switch (notification.data.type) {
    case 'CONSUME_NOTIFICATION':
      yield put(
        notificationActions.markNotificationAsReadSuccess({ id: notification.data.notificationId })
      )
      break
    case 'CONSUME_NOTIFICATIONS_FOR_INCIDENT':
      yield put(
        notificationActions.markNotificationsForIncidentAsReadSuccess({
          incidentId: notification.data.incidentId
        })
      )

      break
    case 'UPDATE_INCIDENT':
      yield put(
        incidentActions.updateIncident({
          id: incident.id,
          changes: incident
        })
      )
      break
    case NotificationType.Panic:
    case NotificationType.Escort:
    case NotificationType.Incident:
      yield put(incidentActions.addIncident(incident))
    case NotificationType.Message:
      yield put(notificationActions.addNotification(normalizeFirebaseNotification(notification)))
    default:
      break
  }
}

function* recieveNotificationHandler() {
  const notificationChannel = yield call(createFirebaseMessagingChannel)
  yield takeEvery(notificationChannel, notificationEventDispatcher)
}
