import styled from "styled-components/macro"
import { colors, fonts } from "../../constants"

export const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 10px
`
interface ILabel {
  inverted: boolean
}
export const Label = styled.p`
  color:  ${(props: ILabel) => props.inverted ? `${colors.black}` : `${colors.ghostWhite}`};
  font-size: 18px;
  margin-bottom: 20px;
`
interface IInput {
  inverted: boolean
}
export const Input = styled.input`
  padding: 16px 20px;
  background: none;
  border: none;
  border-bottom: 1px solid ${colors.midnight};
  font-size: 18px;
  color:  ${(props: IInput) => props.inverted ? `${colors.black}` : `${colors.ghostWhite}`};
  width: 100%;
  margin-bottom: 14px;
  ::placeholder {
    color: ${colors.midnight};
  }
`
interface IInfo {
  inverted: boolean
}
export const Info = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
  color:  ${(props: IInfo) => props.inverted ? `${colors.black}` : `${colors.ghostWhite}`};
`

interface IButton {
  gradient: boolean
}
export const Button = styled.button`
  background: ${(props: IButton) => (props.gradient ? `linear-gradient(279deg, #503bff, #a11de7)` : colors.midnight)};
  margin-top: 10px;
  width: 100%;
  color:  ${colors.ghostWhite};
  border: none;
  font-size: 16px;
  font-family: ${fonts.defaultFont};
  padding: 14px 0;
  &:first-of-type {
    margin-top: 40px;
  }
`