import moment from 'moment';

export const getCreatedAtString = (createdDate?: Date): string => {
  if (!createdDate) {
    return '';
  }

  return moment(createdDate).format('MMM D, hh:mm A');
};

export const renderPassedTime = (date?: Date): string => {
  if (!date) {
    return '';
  }
  return moment(date).fromNow();
};


