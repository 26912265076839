import React from 'react'
import {
  IncidentVideo, WallIncident
} from 'incident-code-core'
import styled from "styled-components/macro"
import ReactPlayer from "react-player"
import { Col, Row } from "reactstrap"

import { incidentService } from "../../services/incident-service"
import { PlayButton } from "../Incident/components/Player/components/PlayButton"

interface IProps {
  incident: WallIncident
}

export const Videos: React.FC<IProps> = ({ incident }) => {
  const [tipVideos, setVideos] = React.useState<IncidentVideo[]>([])
  const [playingVideo, setPlayingVideo] = React.useState<string>();

  const togglePlay = (id: string) => {
    if(id === playingVideo) {
      setPlayingVideo(null);
      return;
    }
    setPlayingVideo(id);
  }

  React.useEffect(() => {
    (async () => {
      const videosCurrently = await incidentService.getVideos(incident.id)
      setVideos(videosCurrently)
    })();

  }, [incident])

  return (
    <Row>
      {tipVideos.map(v =>
        <Col md={3} className="mb-4" key={v.id.toString()}>
          <PlayerContainer
            onClick={() => togglePlay(v.id.toString())}
          >
            <ReactPlayer
              url={v.url}
              width="100%"
              height="100%"
              className="bg-light position-absolute pt-2 pb-2"
              style={{
                top: 0,
                left: 0,
                border: '1px solid #ddd'}}
              playing={playingVideo === v.id.toString()}
              onEnded={() => togglePlay(v.id.toString())}
            />
            <PlayButton
              isPlaying={playingVideo === v.id.toString()}
              sideSize={55}
              onClick={() => togglePlay(v.id.toString())}
            />
          </PlayerContainer>
          <div>
            #{v.uploadId}
          </div>
        </Col>
      )}
    </Row>
  )
}

const PlayerContainer = styled.div`
  position: relative;
  padding-top: 80%;
  cursor: pointer;
`
