import styled from 'styled-components/macro';
import { colors } from '../constants';

export const OverlayGradient = styled.div`
  position: absolute;
  z-index: 1;
  background: -moz-linear-gradient(top, ${
    colors.overlayGradientColors[0]
  } 0%, ${colors.overlayGradientColors[1]} 18%, ${
  colors.overlayGradientColors[2]
} 50%, ${colors.overlayGradientColors[3]} 100%);
  background: -webkit-linear-gradient(top, ${
    colors.overlayGradientColors[0]
  } 0%,${colors.overlayGradientColors[1]} 18%,${
  colors.overlayGradientColors[2]
} 50%,${colors.overlayGradientColors[3]} 100%);
  background: linear-gradient(to bottom, ${
    colors.overlayGradientColors[0]
  } 0%,${colors.overlayGradientColors[1]} 18%,${
  colors.overlayGradientColors[2]
} 50%,${colors.overlayGradientColors[3]} 100%);
  top:0;
  right: 0;
  bottom: 0;
  left: 0;
`;
