import { PayloadAction } from '@reduxjs/toolkit'

export const setLoadingTrue = (state: any) => {
  state.loading = true
}

export const loadingFailed = (state: any, { payload }: PayloadAction<any>) => {
  state.loading = false
  state.error = payload
}
