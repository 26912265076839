import React from 'react';
import { Email } from './Email';

interface IEmailListProps {
  inverted: boolean
  targets: string[];
  onRemove(index: number): void;
}

export const EmailList: React.FC<IEmailListProps> = ({
  inverted, targets, onRemove
}) => (
  <div>
    {targets.map((email, index) => (
      <Email
        email={email}
        index={index}
        key={index}
        inverted={inverted}
        removeEmail={onRemove}
      />
    ))}
  </div>
);
