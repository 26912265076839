import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Popover, PopoverBody } from 'reactstrap'
import NotificationComponent from './NotificationComponent'
import { images, ROUTES } from '../../constants'
import styled from 'styled-components/macro'
import { Id } from 'incident-code-core'
import { withRouter } from 'react-router'

import { notificationActions, notificationSelectors } from '../../store'
import { useNotificationToastHook } from './notificationToastHook'

const ding = new Audio(require('../../assets/audio/notification-ding/notification_ding.mp3'))

export interface NotificationData {
  title: string
  message?: string
  link: string
  type: string
  sentAt: string
  storedId?: string
  incidentId?: string
}

export interface Notification {
  id?: Id
  topic: string
  data: NotificationData
  consumed?: boolean
}

const { selectAllNotificatons, selectIsThereNotifications } = notificationSelectors

const NotificationIcon = ({ match }: any) => {
  useNotificationToastHook()
  const dispatch = useDispatch()

  const notificationList = useSelector(selectAllNotificatons)
  const isThereNotifications = useSelector(selectIsThereNotifications)
  const loadNotifications = () => dispatch(notificationActions.getNotifications())

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false)

  const [notificationDing, setNotificationDing] = useState<any>(null)

  const toggle = () => setPopoverOpen(!popoverOpen)

  useEffect(() => {
    loadNotifications()
  }, [])

  useEffect(() => {
    if (match.path === ROUTES.home) {
      if (isThereNotifications && !notificationDing) {
        setNotificationDing(
          setInterval(() => {
            ding.play()
          }, 2000)
        )
      } else if (!isThereNotifications && !!notificationDing) {
        clearInterval(notificationDing)
        setNotificationDing(null)
      }
    } else if (!!notificationDing) {
      clearInterval(notificationDing)
      setNotificationDing(null)
    }

    return () => {
      if (notificationDing) clearInterval(notificationDing)
    }
  }, [isThereNotifications, notificationDing, match])

  return (
    <NotificationArea>
      <span className="text-white">
        {isThereNotifications ? (
          <NotificationBell src={images.notificationBell} id="notificationsPopOver" />
        ) : (
          <NotificationBell src={images.notificationBellReads} id="notificationsPopOver" />
        )}
      </span>

      <StyledPopover
        placement="bottom"
        isOpen={popoverOpen}
        target="notificationsPopOver"
        toggle={toggle}
      >
        <PopoverBody>
          {notificationList.length ? (
            <NotificationBox>
              New Notifications
              {notificationList.map(notification => (
                <NotificationComponent key={notification.id} notification={notification} />
              ))}
            </NotificationBox>
          ) : (
            <p>There are no notifications at the moment.</p>
          )}
        </PopoverBody>
      </StyledPopover>
    </NotificationArea>
  )
}

const StyledPopover = styled(Popover)`
  min-width: 328px;
  background-color: red;
`

const NotificationArea = styled.div``
const NotificationBox = styled.div`
  height: 500px;
  overflow-x: auto;
`
const NotificationBell = styled.img`
  cursor: pointer;
`

export default withRouter(NotificationIcon)
