import React from 'react';
import {
  WallIncident,
  IncidentType
} from 'incident-code-core';

import { Videos } from "./Videos"
import { Compositions } from "./Compositions"

interface IProps {
  incident: WallIncident
}

export const Uploaded: React.FC<IProps> = ({ incident }) => (
  <div>
    <div className="pt-4 pb-2 font-weight-bold">
      Uploaded Files
    </div>
    <div>
      {incident.type === IncidentType.Normal && <Videos incident={incident} />}
      {incident.type === IncidentType.Escort && <Compositions incident={incident} />}
    </div>
  </div>
)
