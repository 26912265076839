export const openNewWindow = (url: string) => {
  let width = window.screen.availWidth;
  let height = window.screen.availHeight;

  if (navigator.userAgent.match(/Edge\/\d+/g)) {
    width = window.screen.width
    height = window.screen.height
  }

  const newWindow = window.open(
    url,
    '_blank',
    // `toolbar=0,location=0,menubar=0,modal=yes,scrollbars=no,width=${width},height=${height}`,
    `toolbar=0,location=0,menubar=0,modal=yes,scrollbars=yes,directories=no,width=${width - 25},height=${height * .9}`,
    false,);
  newWindow.opener = null;
}
