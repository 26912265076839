import { withFormik } from 'formik'
import * as Yup from 'yup';

import { incidentService } from "../../services/incident-service"
import { AddNoteProps, FormValues } from './interfaces'
import CloseIncidentForm from './Form'

const CloseIncidentSchema = Yup.object().shape({
  comment: Yup.string().trim().min(1).required(),
});

export const CloseIncident = withFormik<AddNoteProps, FormValues>({
  mapPropsToValues: () => ({ comment: "" }),
  validationSchema: CloseIncidentSchema,
  handleSubmit({ comment }: FormValues, { props, setSubmitting, resetForm, setStatus }) {
    incidentService.markIncidentResolved(props.incidentId, comment).then(() => {
      props.onHideForm();
      resetForm({});
      setTimeout(() => window.close(), 2000);
    }).catch((error) => {
      setStatus({message: error.response?.data?.message })
    })
    setSubmitting(false)
  }
})(CloseIncidentForm);

export default CloseIncident;
