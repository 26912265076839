import { AxiosResponse } from 'axios'
import { EventEmitter } from 'events'
import {
  Id,
  IncidentChat,
  IncidentChatRequest,
  IncidentWallAlertRequest,
  WallIncidentResult,
  QueryResult,
  QueryResultPagination,
  WallIncident,
  Organization,
  IncidentType,
  IncidentLocation,
  ChatReport,
  IncidentVideo,
  IncidentPassive
} from 'incident-code-core'
import { ApiService } from './api-service'

import { configService } from './config-service'

export const IncidentListener = new EventEmitter()
export const FetchIncidentsEvent = 'fetchIncident'

class IncidentService extends ApiService {
  fetchTimeId: number = 0
  fetchAllTimeId: number = 0
  timestamp: number = 0
  pagination: QueryResultPagination = { total: 0 }
  incidents: Map<Id, WallIncident> = new Map()
  organization: Organization
  closedIncidents: boolean
  type: null | IncidentType

  public startFetchIncidents = () => {
    this.fetchTimeId = setTimeout(() => {
      this.getIncidents(false, true, this.closedIncidents, this.organization, this.type)
    }, configService.config.fetchInterval)
  }

  public clearFetchInterval = () => {
    if (this.fetchTimeId) {
      clearTimeout(this.fetchTimeId)
      this.fetchTimeId = 0
    }
  }

  private setFilters = (
    closedIncidents: boolean,
    organization: Organization,
    type: IncidentType = null
  ) => {
    this.organization = organization
    this.closedIncidents = closedIncidents
    this.type = type
  }

  public getIncidents = async (
    isInitRequest: boolean = false,
    fetchAgain: boolean = false,
    closedIncidents: boolean = false,
    organization: Organization = null,
    type: IncidentType = IncidentType.Normal
  ) => {

    const timestamp = isInitRequest ? 0 : this.timestamp
    const paths = ['wall', 'incidents']

    const query = {
      timestamp,
      filter: {
        isResolved: closedIncidents,
        type
      }
    }

    if (organization) {
      query.filter = { ...query.filter, ...{ organization: organization.id } }
    }

    this.setFilters(closedIncidents, organization, type)

    const response = await this.get<WallIncidentResult>(paths, query)
    this.setData(response.data, isInitRequest)
    if (fetchAgain) {
      this.startFetchIncidents()
    }
  }

  public getIncidentById = async (id: string): Promise<AxiosResponse<WallIncident>> => {
    const paths = ['wall', 'incident', id]
    const response = await this.get<WallIncident>(paths)
    return {
      ...response,
      data: {
        videos: [],
        ...response.data
      }
    }
  }

  public getReportIncidentById = async (id: string): Promise<AxiosResponse<WallIncident>> => {
    const paths = ['incidents', id]
    const response = await this.get<WallIncident>(paths)
    return {
      ...response,
      data: {
        videos: [],
        ...response.data
      }
    }
  }

  getChat(id: Id): Promise<AxiosResponse<IncidentChat>> {
    return this.get(['wall', 'chat', id])
  }

  public async getIncidentChat(id: Id): Promise<IncidentChat> {
    const result = await this.get(['wall', id, 'report'])
    return result.data
  }

  public async getReportChat(id: Id): Promise<ChatReport> {
    const result = await this.get(['wall', id, 'report'])
    return result.data
  }

  public async startChat(incident: WallIncident): Promise<AxiosResponse> {
    if (!incident.hasChat) {
      const request: IncidentChatRequest = { incident: incident.id }
      return await this.post(['wall', 'chat'], request)
    }
  }

  public async getEscortLocation(id: string): Promise<IncidentLocation> {
    const result = await this.get(['wall', id, 'location'])
    return result.data
  }

  private setData = (result: WallIncidentResult, reset: boolean) => {
    const { timestamp, pagination, data } = result

    this.timestamp = timestamp

    if (reset) {
      this.incidents.clear()
      this.pagination = pagination
    }
    this.addData(data, !reset)

    if (data.length === 0 && !reset) {
      return
    }

    this.fetchTrigger()
  }

  public getCurrentData = (): QueryResult => {
    const data = Array.from(this.incidents.values()).sort((a, b) => {
      return b.createdAt.valueOf() - a.createdAt.valueOf()
    })
    return {
      pagination: this.pagination,
      data
    }
  }

  public fetchNextPage = async (
    oldIndex: number,
    closedIncidents: boolean = false,
    organization: Organization = null,
    type: IncidentType = IncidentType.Normal
  ) => {
    const nextIndex = oldIndex + 1

    const query = {
      pagination: {
        index: nextIndex
      },
      filter: {
        isResolved: closedIncidents,
        type
      }
    }

    if (organization) {
      query.filter = { ...query.filter, ...{ organization: organization.id } }
    }

    this.setFilters(closedIncidents, organization, type)

    const paths = ['wall', 'incidents']
    const response = await this.get<WallIncidentResult>(paths, query)

    this.addData(response.data.data, false)

    this.pagination = response.data.pagination
    this.fetchTrigger()
  }

  private fetchTrigger = () => {
    IncidentListener.emit(FetchIncidentsEvent, this.getCurrentData())
  }

  private markIncidentResolvedLocal = (incidentId: Id) => {
    const incident = this.incidents.get(incidentId)
    if (incident) {
      incident.isResolved = true
      this.fetchTrigger()
      return
    }
    console.warn('Incident was not found')
  }

  public markIncidentResolved = async (id: Id, comment: string): Promise<void> => {
    const url = ['wall', 'mark-resolved']
    const payload = { id, comment }
    this.markIncidentResolvedLocal(id)
    this.post(url, payload)
  }

  public shareIncident = async (
    targets: string[],
    incidentId?: Id,
    isReport: boolean = false
  ): Promise<void> => {
    const sharePath = isReport ? 'share-report' : 'share'
    const url = ['wall', sharePath]
    this.post(url, {
      incidentId,
      targets
    })
  }

  public generatePublicCode = async (id:string) => {
    return await this.post(["wall","generatePublicCode"], {id})
  }

  public sendMessage = async (payload: IncidentWallAlertRequest) => {
    const url = ['wall', 'alert']
    return await this.post(url, payload)
  }

  private addData(data: WallIncident[], increaseTotal: boolean) {
    data.forEach(incident => {
      incident.createdAt = new Date(incident.createdAt as any)
      if (increaseTotal && !this.incidents.has(incident.id)) {
        // the data might include the existing incidents, if has, not increase total
        this.pagination.total += 1
      }
      this.incidents.set(incident.id, incident)
    })
  }

  public getVideos = async (incidentId: Id): Promise<IncidentVideo[]> => {
    const response = await this.get(['incidents', incidentId, 'videos'])
    return response.data.data
  }

  public getAllIncidents = async (
    reset: boolean = false,
    fetchAgain: boolean = false,
    closedIncidents: boolean = false,
    organization: Organization = null,
    type: null | IncidentType = null
  ) => {
    const paths = ['wall', 'incidents']

    const query = {
      timestamp: 0,
      filter: { isResolved: closedIncidents }
    }

    if (organization !== null) {
      query.filter = { ...query.filter, ...{ $or: [{ organization: organization.id }] } }
    }

    if (type === null) {
      query.filter = { ...query.filter }
    } else {
      query.filter = { ...query.filter, ...{ type } }
    }

    this.setFilters(closedIncidents, organization, type)

    const response = await this.get<WallIncidentResult>(paths, query)

    this.setData(response.data, reset)

    if (fetchAgain) {
      this.startFetchAllIncidents()
    }
  }

  public fetchAllNextPage = async (
    oldIndex: number,
    closedIncidents: boolean = false,
    organization: Organization = null,
    type: null | IncidentType = null
  ) => {
    const nextIndex = oldIndex + 1

    const query = {
      pagination: {
        index: nextIndex
      },
      filter: { isResolved: closedIncidents }
    }

    if (organization !== null) {
      query.filter = { ...query.filter, ...{ organization: organization.id } }
    }

    if (type === null) {
      query.filter = { ...query.filter }
    } else {
      query.filter = { ...query.filter, ...{ type } }
    }

    this.setFilters(closedIncidents, organization, type)

    const paths = ['wall', 'incidents']


    const response = await this.get<WallIncidentResult>(paths, query)

    this.addData(response.data.data, false)

    this.pagination = response.data.pagination
    this.fetchTrigger()
  }

  public startFetchAllIncidents = () => {
    let timer = 9000
    if (this.closedIncidents) {
      timer = configService.config.fetchInterval
    }

    this.fetchAllTimeId = setTimeout(() => {
      this.getAllIncidents(true, true, this.closedIncidents, this.organization, this.type)
    }, timer)
  }

  public clearFetchAllInterval = () => {
    if (this.fetchAllTimeId) {
      clearTimeout(this.fetchAllTimeId)
      this.fetchAllTimeId = 0
    }
  }

  public getAuditLog = async (incidentId: Id) => {
    const paths = ['wall', incidentId, 'escort', 'log']
    const response = await this.get<IncidentPassive>(paths)
    return response.data
  }
}

export const incidentService = new IncidentService()
