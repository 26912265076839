import styled from 'styled-components/macro'
import { colors, fonts } from '../../constants'

interface IButton {
  gradient: boolean
}

export const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
`

export const Label = styled.p`
  color: ${colors.black};
  font-size: 18px;
  margin-top: 10px;
`

export const Select = styled.select`
  background: ${colors.white};
  border: 1px solid ${colors.lightGray};
  color: ${colors.black};
  margin-bottom: 5px;
  padding: 10px;
  width: 100%;

  ::placeholder {
    color: ${colors.midnight};
  }

  > option {
    color: ${colors.black};
  }
`

export const Input = styled.input`
  background: ${colors.white};
  border: 1px solid ${colors.lightGray};
  color: ${colors.black};
  font-size: 18px;
  margin-bottom: 5px;
  padding: 10px;
  width: 100%;
  border-radius: 5px;

  ::placeholder {
    color: ${colors.midnight};
  }
`

export const Textarea = styled.textarea`
  background: ${colors.white};
  border: 1px solid ${colors.lightGray};
  color: ${colors.black};
  font-size: 18px;
  margin-bottom: 5px;
  padding: 10px;
  resize: none;
  width: 100%;
  border-radius: 5px;

  ::placeholder {
    color: ${colors.midnight};
  }
`

export const CheckboxContainer = styled.div`
  margin-top: 20px;
`

export const SendButton = styled.button`
  background: linear-gradient(104deg, #503bff, #4632ec);
  border: none;
  border-radius: 5px;
  color: ${colors.ghostWhite};
  font-family: ${fonts.defaultFont};
  font-size: 16px;
  padding: 14px 0;
  width: 100%;

  &:first-of-type {
    margin-top: 40px;
  }
`

export const CancelButton = styled.button`
  background: ${colors.midnight};
  border: none;
  border-radius: 5px;
  color: ${colors.ghostWhite};
  font-family: ${fonts.defaultFont};
  font-size: 16px;
  margin-top: 10px;
  padding: 14px 0;
  width: 100%;
`

export const RedMessage = styled.div`
  color: ${colors.secondary};
`
