import { EventEmitter } from "events"
import { Dict, WallIncident, ChatMessage } from "incident-code-core"
import { Channel } from "twilio-chat/lib/channel"
import { Client as TwilioClient } from "twilio-chat/lib/client"
import { Message as TwilioMessage } from "twilio-chat/lib/message"
import { incidentService } from "./incident-service"
import { twilioAuthService } from "./twilio-auth-service"

export const ChatListener = new EventEmitter()
export const ClientMessageAddedEvent = "clientMessageAdded"

export interface ChatEventHandler {
  onMessageAdded: (messages: ChatMessage) => void
  onJoined: () => void
  onConnectionStateChanged: () => void
  onMemberJoined: () => void
}

export class ChatService {
  client: TwilioClient
  channel: Channel
  incident: WallIncident
  memberMap: Dict<string> = {}
  eventHandler: ChatEventHandler
  readonlyMode: boolean

  async init(incident?: WallIncident) {
    this.incident = incident
    if (this.client) return this.client

    const token = await twilioAuthService.getToken(this.incident, true)
    if (token === null) return
    this.client = await TwilioClient.create(token)
    this.client.on("tokenAboutToExpire", this.refreshToken)
    this.client.on("messageAdded", this.onClientSentMessage)
    this.client.on("connectionStateChanged", this.onConnectionStateChanged)

    return this.client
  }

  async initChat(eventHandler: ChatEventHandler, connectionHandler?: any): Promise<void> {
    this.eventHandler = eventHandler
    this.readonlyMode = !!(this.incident && this.incident.isResolved);
    if (!this.readonlyMode && this.channel == null) { return await this.initChannel(connectionHandler && connectionHandler) }
  }

  async close() { if (this.client) { await this.client.shutdown() } }

  async leave() { if (this.channel) { await this.channel.leave() } }

  send(message: string): void {
    if (this.readonlyMode) return
    this.sendInternal(message)
  }

  async loadMessages(): Promise<ChatMessage[]> {
    let messages: ChatMessage[] = []
    if (this.readonlyMode) {
      const incidentChat = await incidentService.getChat(this.incident.id)
      if (incidentChat && incidentChat.data.messages) {
        messages = incidentChat.data.messages
      }
    } else {
      if (this.incident && this.incident.hasChat) {
        if (this.channel == null) { await this.initChannel() }
        if (this.channel) {
          if (this.channel.status === 'joined' && this.eventHandler.onJoined) {
            this.eventHandler.onJoined()
          }
          const twilioMessages = await this.channel.getMessages()
          messages = twilioMessages.items.map(this.convertTwilioMessage)
        }
      }
    }

    if (messages.length > 0) { return messages }
    return []
  }

  getUserId(memberId: string): string { return this.memberMap[memberId] }

  private async sendInternal(message: string): Promise<void> {
    if (this.channel == null) { await this.initChannel() }
    this.channel.sendMessage(message)
  }

  private async initChannel(handleConnectionStatus?: any): Promise<any> {
    await incidentService.startChat(this.incident)
    this.channel = await this.client.getChannelByUniqueName(this.incident.id.toString())
    this.channel.on("messageAdded", this.onMessageAddedInternal)
    this.channel.on("memberJoined", this.onMemberJoined)
    if (this.channel && this.channel.status !== "joined") {
      try {
        await this.channel.join()
        handleConnectionStatus && handleConnectionStatus(true)
      } catch (error) {
        handleConnectionStatus && handleConnectionStatus(false)
      }

    }
    const members = await this.channel.getMembers()
    members.forEach(member => { this.memberMap[member.sid] = member.identity })
    this.channel.setAllMessagesConsumed()

    window.addEventListener('beforeunload', async () => {
      await this.channel.leave()
    })

    return this.channel
  }

  private onMessageAddedInternal = (message: TwilioMessage) => {
    // the receive the message, make it is read
    this.channel.setAllMessagesConsumed()
    if (this.eventHandler.onMessageAdded) {
      this.eventHandler.onMessageAdded(this.convertTwilioMessage(message))
    }
  }

  private onConnectionStateChanged = (ev: any) => {}

  private onMemberJoined = (ev: any) => {}

  private refreshToken = async () => {
    const token = await twilioAuthService.getToken(this.incident, true)
    this.client.updateToken(token)
  }

  private convertTwilioMessage = (message: any): ChatMessage => {
    const { address, location } = message.attributes

    return {
      id: message.sid,
      content: message.body,
      createdAt: message.timestamp,
      from: this.memberMap[message.memberSid],
      address,
      location
    }
  }

  private onClientSentMessage = (message: TwilioMessage) => {
    if (this.client.user.identity !== message.author) {
      ChatListener.emit(ClientMessageAddedEvent, message)
    }
  };
}

export const chatService = new ChatService()
