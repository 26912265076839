import { createEntityAdapter } from '@reduxjs/toolkit'

export interface INotification {
  id: string
  incidentId: string
  type: string
  title: string
  link: string
  consumed: boolean
  recievedFromFirebase: boolean
  createdAt: Date
}

const notificationAdapter = createEntityAdapter<INotification>({
  selectId: ({ id }) => id,
  sortComparer: (a: any, b: any) =>
    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
})

export default notificationAdapter
