import React from "react"
import styled from "styled-components/macro"

import { colors, strings } from "../../../../constants"
import IOtherVideoProps from "./interfaces"
import SingleVideo from "./SingleVideo"
import SlideVideos from "./SlideVideos"

const Videos: React.FC<IOtherVideoProps> = props => {
  const { videos } = props

  if (videos.length <= 4) {
    return (
      <Container>
        <Title>{strings.incidentDetail.detailTitles.uploadedVideos}</Title>
        <SingleVideo {...props} />
      </Container>
    )
  } else {
    return (
      <div>
        <Title>{strings.incidentDetail.detailTitles.uploadedVideos}</Title>
        <SlideVideos {...props} />
      </div>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: stretch;
  min-height: 20vh;
  margin-top: 1em;
`

const Title = styled.h3`
  color: ${colors.midnight};
  font-size: 14px;
  line-height: 1.29;
  margin-bottom: 0.3em;
  margin-top: -1.5em;
`

export default Videos
