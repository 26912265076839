import logo from '../assets/images/logo.png';
import logOut from '../assets/images/logout.png';
import ic_play from '../assets/images/play.png';
import ic_play_2 from '../assets/images/play.svg';
import ic_pause from '../assets/images/pause.png';
import close from '../assets/images/close.png';
import pinCluster from '../assets/images/pinCluster.svg';
import back from '../assets/images/back.png';
import next from '../assets/images/next.png';
import notificationBell from '../assets/images/notifications_button.png';
import notificationBellReads from '../assets/images/notifications_button_reads.png';
import video from '../assets/images/video.png';
import audio from '../assets/images/audio.png';
import defaultImage from '../assets/images/default-image.svg'

// blank person is randomly grabbed off the internet
import blankPerson from '../assets/images/blank_person.png'

export const images = {
  logo,
  logOut,
  ic_play,
  ic_play_2,
  ic_pause,
  close,
  pinCluster,
  back,
  next,
  notificationBell,
  notificationBellReads,
  video,
  audio,
  defaultImage,
  blankPerson
};
