import React from 'react';
import { FormikProps } from 'formik';

import { ChatFormProps, FormValues } from './interfaces';
import { SendButton } from './SendButton';
import { Form } from './styles';

const ChatForm: React.FC<ChatFormProps & FormikProps<FormValues>> = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  isResolved
}) => (
  <Form onSubmit={handleSubmit}>
    <input
      disabled={isResolved}
      style={{width: "100%"}}
      placeholder="Type the message"
      type="text"
      name="message"
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.message}
    />
    <SendButton disabled={isSubmitting || isResolved} />
  </Form>
);



export default ChatForm;