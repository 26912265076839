import * as React from 'react';
import { withRouter, Switch, RouteComponentProps } from 'react-router-dom';

import { ROUTES } from '../../constants';
import { incidentService } from '../../services/incident-service';
import { escortService } from '../../services/escort-service';
import { configService } from '../../services/config-service';
import {ApiListener, TokenExpiredEvent} from "../../services/api-service";
import {appSetting} from "../../services/app-setting";

interface IAppContainerProps extends RouteComponentProps { }

class _AppContainer extends React.Component<IAppContainerProps> {
  prevPath = '';

  async componentDidMount() {
    await configService.init();
    const { pathname } = this.props.location;

    this.prevPath = pathname;


    ApiListener.on(TokenExpiredEvent, ({ message }) => {
      appSetting.clearStorage();
      this.props.history.replace(ROUTES.login);
      alert(message);
    });
  }
  async componentDidUpdate() {
    const { pathname } = this.props.location;

    const isLoginScreen = pathname === ROUTES.login;

    if (isLoginScreen) {
      incidentService.clearFetchInterval();
      escortService.clearFetchInterval();
      this.prevPath = ROUTES.login;
      return;
    }
  }

  render() {
    return (
      <Switch>
        {this.props.children}
      </Switch>
    )
  }
}

export const AppContainer = withRouter(_AppContainer);
