import { AxiosResponse } from "axios"
import { EventEmitter } from "events"
import {
  Id,
  IncidentChat,
  IncidentChatRequest,
  IncidentWallAlertRequest,
  WallIncidentResult,
  QueryResult,
  QueryResultPagination,
  WallIncident,
  Organization,
  IncidentType,
  IncidentEscortState,
  IncidentEscort,
  IncidentEscortStateRequest,
  IncidentLocation,
  MediaLinkResponse
} from "incident-code-core"
import { ApiService } from "./api-service"

import { configService } from "./config-service"

export const IncidentListener = new EventEmitter()
export const FetchIncidentsEvent = "fetchEscort"

class EscortService extends ApiService {
  fetchTimeId: number = 0
  timestamp: number = 0
  pagination: QueryResultPagination = { total: 0 }
  incidents: Map<Id, WallIncident> = new Map()
  organization: Organization
  closedIncidents: boolean
  type: IncidentType = IncidentType.Escort

  public startFetchIncidents = () => {
    this.fetchTimeId = setTimeout(() => {
      this.getIncidents(false, true, this.closedIncidents, this.organization, this.type)
    }, configService.config.fetchInterval)
  }

  public clearFetchInterval = () => {
    if (this.fetchTimeId) {
      clearTimeout(this.fetchTimeId)
      this.fetchTimeId = 0
    }
  }

  private setFilters = (
    closedIncidents: boolean,
    organization: Organization,
    type: IncidentType = IncidentType.Escort
  ) => {
    this.organization = organization;
    this.closedIncidents = closedIncidents;
    this.type = type;
  }

  public getIncidents = async (isInitRequest: boolean = false,
                               fetchAgain: boolean = false,
                               closedIncidents: boolean = false,
                               organization: Organization = null,
                               type: IncidentType = IncidentType.Escort) => {
    const timestamp = isInitRequest ? 0 : this.timestamp
    const paths = ["wall", "incidents"]
    // let state = []
    // if (closedIncidents) {
    //   state = ["closed", "safe", "expired", "cancelled"]
    // } else {
    //   state = ["active", "panic", "background", "unknown"]
    // }

    const query = {
      timestamp,
      filter: {
        isResolved: closedIncidents,
        type,
      }
    }

    if (organization) {
      query.filter = {...query.filter, ...{ organization: organization.id}}
    }

    this.setFilters(closedIncidents, organization, type);

    const response = await this.get<WallIncidentResult>(paths, query)
    this.setData(response.data, isInitRequest)
    if (fetchAgain) {
      this.startFetchIncidents()
    }
  }

  public getIncidentById = async (id: string): Promise<AxiosResponse<WallIncident>> => {
    const paths = ["wall", "incident", id]
    const response = await this.get<WallIncident>(paths)
    return {
      ...response,
      data: {
        videos: [],
        ...response.data,
      }
    }
  }

  getChat(id: Id): Promise<AxiosResponse<IncidentChat>> {
    return this.get(["wall", "chat", id])
  }

  public async startChat(incident: WallIncident): Promise<AxiosResponse> {
    if (!incident.hasChat) {
      const request: IncidentChatRequest = { incident: incident.id}
      return await this.post(["wall", "chat"], request)
    }
  }

  private setData = (result: WallIncidentResult, reset: boolean) => {
    const { timestamp, pagination, data } = result

    this.timestamp = timestamp

    if (reset) {
      this.incidents.clear()
      this.pagination = pagination
    }
    this.addData(data, !reset)

    if (data.length === 0 && !reset) {
      return
    }

    this.fetchTrigger()
  }

  public getCurrentData = (): QueryResult => {
    const data = Array.from(this.incidents.values()).sort((a, b) => {
      return b.createdAt.valueOf() - a.createdAt.valueOf()
    })
    return {
      pagination: this.pagination,
      data
    }
  }

  public fetchNextPage = async (oldIndex: number,
                                closedIncidents: boolean = false,
                                organization: Organization = null,
                                type: IncidentType = IncidentType.Escort
                                ) => {
    const nextIndex = oldIndex + 1
    // let state = []
    // if (closedIncidents) {
    //   state = ["closed", "safe", "expired", "cancelled"]
    // } else {
    //   state = ["active", "panic", "background", "unknown"]
    // }

    const query = {
      pagination: {
        index: nextIndex
      },
      filter: {
        isResolved: closedIncidents,
        type,
      }
    }

    if (organization) {
      query.filter = {...query.filter, ...{ organization: organization.id}}
    }

    this.setFilters(closedIncidents, organization, type);

    const paths = ["wall", "incidents"]
    const response = await this.get<WallIncidentResult>(paths, query)

    this.addData(response.data.data, false)

    this.pagination = response.data.pagination
    this.fetchTrigger()
  }

  private fetchTrigger = () => {
    IncidentListener.emit(FetchIncidentsEvent, this.getCurrentData())
  }

  private markIncidentResolvedLocal = (incidentId: Id) => {
    const incident = this.incidents.get(incidentId)
    if (incident) {
      incident.isResolved = true
      this.fetchTrigger()
      return
    }
    console.warn("Incident was not found")
  }

  public markIncidentResolved = async (incidentId: Id): Promise<void> => {
    const url = ["wall", "mark-resolved"]
    const data = {
      id: incidentId
    }
    this.markIncidentResolvedLocal(incidentId)

    this.post(url, data)
  }

  public shareIncident = async (targets: string[], incidentId?: Id): Promise<void> => {
    const url = ["wall", "share"]
    this.post(url, {
      incidentId,
      targets
    })
  }

  public sendMessage = async (payload: IncidentWallAlertRequest) => {
    const url = ["wall", "alert"]
    return await this.post(url, payload)
  }

  private addData(data: WallIncident[], increaseTotal: boolean) {
    data.forEach(incident => {
      incident.createdAt = new Date(incident.createdAt as any)
      if (increaseTotal && !this.incidents.has(incident.id)) {
        // the data might include the existing incidents, if has, not increase total
        this.pagination.total += 1
      }
      this.incidents.set(incident.id, incident)
    })
  }

  public async getIncidentChat(id: Id): Promise<IncidentChat> {
    const result = await this.get(["wall", id, "report"]);
    return result.data;
  }

  public updateEscortState = async (
    id: string,
    statusUpdated: IncidentEscortState,
    comment?: string
  ): Promise<void> => {
    const url = ["wall", id, "escort", "state"]
    let payload: IncidentEscortStateRequest = { state: statusUpdated }
    if (comment) {
      payload = { state: statusUpdated, comment}
    }
    const result = await this.patch(url, payload);
    return result.data;
  }

  public getEscort = async (id: string): Promise<AxiosResponse<IncidentEscort>> => {
    const url = ["wall", id, "escort"]
    return await this.get(url);
  }

  public async getEscortLocation(id: string): Promise<IncidentLocation> {
    const result = await this.get(["wall", id, "location"]);
    return result.data;
  }

  public async getMediaLink(id: string): Promise<MediaLinkResponse> {
    const result = await this.get(["incidents", "media-link", id]);
    return result.data;
  }

}

export const escortService = new EscortService()
