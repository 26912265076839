import React from 'react'
import { isEmail, isPhoneNumber } from 'incident-code-core'

import { strings } from '../../constants'
import { incidentService } from '../../services/incident-service'
import { EmailList } from './EmailList'
import { IFormProps, IFormState } from './interfaces'
import { Label, Input, Button, Info } from './styles'

export class ShareForm extends React.Component<IFormProps, IFormState> {
  state: IFormState = {
    input: '',
    targets: []
  }

  onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      input: e.currentTarget.value
    })
  }

  onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (!isPhoneNumber(this.state.input) && !isEmail(this.state.input)) {
      return
    }
    this.setState(state => ({
      targets: [...state.targets, state.input],
      input: ''
    }))
  }

  onRemoveEmail = (index: number) => {
    this.setState({
      targets: this.state.targets.filter((email, key) => key !== index)
    })
  }

  onShare = async () => {
    const { incidentId, onHideForm } = this.props
    const { targets } = this.state

    try {
      await incidentService.shareIncident(targets, incidentId, !!this.props.isReport)
      onHideForm()
    } catch (err) {
      console.error('OnShare', err)
    }
  }

  render() {
    const { inverted } = this.props
    const { targets, input } = this.state
    const isTargetEmpty = targets.length === 0

    return (
      <>
        <Label inverted={inverted}>{strings.incidentDetail.generateLink.label}</Label>
        <form onSubmit={this.onSubmit}>
          <Input
            inverted={inverted}
            value={input}
            onChange={this.onInputChange}
            type="text"
            placeholder={strings.incidentDetail.generateLink.placeholder}
          />
          <Info inverted={inverted}>Press "Enter" after an email or a phone number to add it</Info>
        </form>
        <EmailList inverted={inverted} onRemove={this.onRemoveEmail} targets={targets} />
        <Button onClick={this.onShare} disabled={isTargetEmpty} gradient={true}>
          {strings.incidentDetail.generateLink.button}
        </Button>
        <Button onClick={this.props.onHideForm} gradient={false}>
          {strings.incidentDetail.buttons.cancel}
        </Button>
      </>
    )
  }
}
