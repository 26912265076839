import { INotification } from './adapter'

export const normalizeNotificationHistory = ({
  id,
  createdAt,
  consumed,
  data: { incidentId, type, link, title }
}: {
  id: string
  createdAt: Date
  consumed: boolean
  data: { incidentId: string; type: string; link: string; title: string }
}) => {
  if (id && createdAt && incidentId && type && link && title && typeof consumed === 'boolean') {
    return {
      id,
      incidentId,
      type,
      title,
      link,
      consumed,
      recievedFromFirebase: false,
      createdAt: new Date(createdAt)
    } as INotification
  }
}

export const normalizeFirebaseNotification = ({

  data: { storedId: id, incidentId, type, title, link, sentAt }
}: {
  data: { storedId: string; incidentId: string; type: string; title: string; link: string, sentAt: string }
}) =>
  ({
    id,
    incidentId,
    type,
    title,
    link,
    consumed: false,
    recievedFromFirebase: true,
    createdAt: new Date(sentAt)
  } as INotification)
