import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

import IOtherVideoProps from './interfaces'
import Preview from './Preview'

const SimpleSlider: React.FC<IOtherVideoProps> = (props) => {
  const { videos } = props
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    autoplay: true,
    centerPadding: "60px",
    slidesToShow: 2,
    speed: 500
  };

  return (

    <Slider {...settings}>
      {videos.map((video, index) => {
        const { thumbnailUrl, id, uploadId } = video
        const { id: currentId } = props.currentVideo
        const onClick = () => props.onPreviewClick(id)
        return (
          <Preview
            fullwidth={true}
            key={index}
            thumbnailUrl={thumbnailUrl}
            currentId={currentId}
            id={id}
            index={index}
            uploadId={uploadId}
            handleClick={onClick}
          />
        )
      })}
    </Slider>
  );
}

interface IVideoPreviewProps {
  thumbnail?: string
}

export default SimpleSlider
