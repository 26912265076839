import Axios from 'axios';
import { Env } from "../constants/env"
import { appSetting, KeyToken } from "../services/app-setting"

const headers = {
  "Content-Type": "application/json",
  "Security-Code": Env.apiSecurityCode,
  "Client-Version": Env.appVersion
} as any

const axios = Axios.create({
  baseURL: Env.apiUrl,
  headers
})

// intercept all requests from this instance
axios.interceptors.request.use((config) => {
  if (!config.headers.Authorization && appSetting.isLoggedIn) {

    const token = appSetting.getValue(KeyToken)
    const authToken = `${token.token_type} ${token.access_token}`

    axios.defaults.headers.common["Authorization"] = authToken // set auth headers on instance
    config.headers["Authorization"] = authToken // set auth headers for this request
  }

  return config
})

// intercept all responses from this instance
axios.interceptors.response.use(null, (error) => {
  if (error.response.status === 401) {
    delete axios.defaults.headers.common.Authorization
  }

  return Promise.reject(error)
})

export {
  axios
}