import React from 'react'
import { WallIncident, AppUser } from 'incident-code-core'
import { Col, Row } from 'reactstrap'
import moment from 'moment'
import styled from 'styled-components/macro'
import { USPhoneFormat } from '../../utils/formatPhoneNumbers'
import { UserIcon } from '../../components'

interface Incident extends WallIncident {
  createdBy: AppUser
}

interface IProps {
  incident: Incident
  color?: string
}

export const Detail: React.FC<IProps> = ({ incident, color }) => {
  return (
    <div>
      <Row className="mt-2 mb-4">
        <Column md={6}>
          <UserIcon user={incident.createdBy as AppUser} margin="0 auto 0 0" height={250} />
        </Column>
        <Column md={6}>
          <Row>
            <Column className="h4 font-weight-bold text-left" color={color}>
              {incident.createdBy.firstName} {incident.createdBy.lastName}
            </Column>
          </Row>
          <Row>
            <Column className="h4 font-weight-bold text-left" color={color}>
              {incident.id}
            </Column>
          </Row>
        </Column>
      </Row>
      <Row></Row>
      <Row className="mb-2">
        <Column color={color} md={6}>
          <div className="font-weight-bold">Contact</div>
          <div>{USPhoneFormat(incident.createdBy.phone)}</div>
        </Column>
        <Column color={color} md={6}>
          <div className="font-weight-bold">Nearby address</div>
          <div>{incident.address}</div>
        </Column>
      </Row>
      <Row>
        <Column color={color} md={6}>
          <div className="font-weight-bold">Incident Type</div>
          <div>{incident.type}</div>
        </Column>
        <Column color={color} md={6}>
          <div className="font-weight-bold">Date and time</div>
          <div>{moment(incident?.createdAt).format('MMM DD LT')}</div>
        </Column>
      </Row>
    </div>
  )
}

interface IColumnProps {
  color: string
}

const Column = styled(Col)`
  color: ${(props: IColumnProps) => (props.color ? props.color : 'black')};
`
