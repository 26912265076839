import { createSlice, PayloadAction, Update, EntityId } from '@reduxjs/toolkit'
import { setLoadingTrue, loadingFailed } from '../utils'
import adapter from './adapter'

const selectors = adapter.getSelectors()

export const initialState = adapter.getInitialState({
  loading: false,
  error: null
})

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getNotifications: setLoadingTrue,
    getNotificationsSuccess: (state, action) => {
      adapter.upsertMany(state, action.payload)
      state.loading = false
    },
    getNotificationsFailed: loadingFailed,
    addNotification: adapter.upsertOne,
    updateNotification: adapter.updateOne,

    markNotificationAsRead: (state, { payload: { id } }: PayloadAction<{ id: EntityId }>) => {
      adapter.updateOne(state, { id, changes: { consumed: true } })
    },

    markNotificationAsReadSuccess: (state, { payload: { id } }: PayloadAction<{ id: EntityId }>) => {
      adapter.removeOne(state, id)
    },

    markNotificationAsReadFailed: (
      state,
      { payload: { id, error } }: PayloadAction<{ id: EntityId; error: Error }>
    ) => {
      state.error = error
      adapter.updateOne(state, { id, changes: { consumed: false } })
    },
    markNotificationsForIncidentAsRead: (
      state,
      { payload: { incidentId } }: PayloadAction<{ incidentId: EntityId }>
    ) => {
      adapter.updateMany(
        state,
        selectors.selectAll(state).reduce((updates: Update<{ consumed: true }>[], notification) => {
          if (notification.incidentId === incidentId)
            updates.push({ id: notification.id, changes: { consumed: true } })
          return updates
        }, [])
      )
    },

    markNotificationsForIncidentAsReadSuccess: (
      state,
      { payload: { incidentId } }: PayloadAction<{ incidentId: EntityId }>
    ) => {
      adapter.removeMany(
        state,
        selectors.selectAll(state).reduce((notificationIds, notification) => {
          if (notification.incidentId === incidentId) notificationIds.push(notification.id)
          return notificationIds
        }, [])
      )
    },

    markNotificationsForIncidentAsReadFailed: (
      state,
      { payload: { incidentId, error } }: PayloadAction<{ incidentId: EntityId; error: Error }>
    ) => {
      state.error = error
      adapter.updateMany(
        state,
        selectors
          .selectAll(state)
          .reduce((updates: Update<{ consumed: false }>[], notification) => {
            if (notification.incidentId === incidentId)
              updates.push({ id: notification.id, changes: { consumed: false } })
            return updates
          }, [])
      )
    }
  }
})

export const notificationActions = slice.actions
export const notificationReducer = slice.reducer
