import React from "react"
import styled from "styled-components/macro"
import { IncidentEscort } from "incident-code-core"

import { strings } from "../../../constants"
import { ButtonComponent } from "../../../components"


interface IIncidentActionsProps {
  escort?: IncidentEscort
  isClosed: boolean
  showGenerateLinkOverlay(): void
  showAddNoteOverlay(): void
  showCloseEscortOverlay(): void
  showMediasLink(): void
}

export const Actions: React.FC<IIncidentActionsProps> = ({
  escort,
  isClosed,
  showCloseEscortOverlay,
  showAddNoteOverlay,
  showGenerateLinkOverlay,
  showMediasLink
}) => (
  <Container>
    {!isClosed && <ButtonComponent
      className="closeEscort"
      text={strings.incidentDetail.buttons.resolve}
      isGradient={true}
      onClick={showCloseEscortOverlay}
    />}
    {
      (
        escort?.isCompositionReady ||
        escort?.hasComposition
      ) &&
      <ButtonComponent
        className="openMedias"
        text="Medias"
        isGradient={false}
        onClick={showMediasLink}
      />
    }
    <ButtonComponent
      text={strings.incidentDetail.buttons.link}
      isGradient={false}
      onClick={showGenerateLinkOverlay}
    />
    <ButtonComponent
      className="addNote"
      text={strings.incidentDetail.buttons.addNote}
      isGradient={false}
      onClick={showAddNoteOverlay}
    />
  </Container>
);


const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: .5em;
  margin-bottom: 0.5em;
`
