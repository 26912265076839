import React from 'react';

interface EscortCardStateLabelProps {
  state?: string
  timerExpired?: boolean
  isPanic?: boolean
  type?: string
}

const EscortCardStateLabel:React.FC<EscortCardStateLabelProps> = ({ type, state, timerExpired, isPanic }) => {
  if (!timerExpired && !(isPanic && state === 'pending')) return null;


  return <span style={{color: "red"}}>{!!timerExpired && "TIMER EXPIRED "} {(isPanic && state === 'pending') && "PANIC "} {state === "pending" && "- Pending"}</span>
}

export {
 EscortCardStateLabel
}