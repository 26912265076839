import React from "react"
import { WallIncident } from "incident-code-core"
import styled from "styled-components/macro"

import { strings } from "../../../constants"
import { ButtonComponent } from "./Button"
import { CloseButton } from "./CloseButton"

interface IIncidentActionsProps {
  incident: WallIncident
  showGenerateLinkOverlay(): void
  showAddNoteOverlay(): void
  showCloseIncidentOverlay(): void
}

export const Actions: React.FC<IIncidentActionsProps> = ({
  incident: { isResolved },
  showGenerateLinkOverlay,
  showAddNoteOverlay,
  showCloseIncidentOverlay
}) => (
  <Container>
    <CloseButton
      isResolved={isResolved}
      showCloseIncidentOverlay={showCloseIncidentOverlay}
    />

    <ButtonComponent
      text={strings.incidentDetail.buttons.link}
      isGradient={false}
      onClick={showGenerateLinkOverlay}
    />

    <ButtonComponent
      text={strings.incidentDetail.buttons.addNote}
      isGradient={false}
      onClick={showAddNoteOverlay}
    />
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: .1em;
  margin-bottom: 0.1em;
`
