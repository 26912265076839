import React from 'react'
import { FormikProps, ErrorMessage } from 'formik'

import { strings } from '../../constants'
import {
  Wrapper,
  Form,
  Label,
  Select,
  Input,
  RedMessage,
  Textarea,
  CheckboxContainer,
  SendButton,
  CancelButton
} from './styles'

import { FilterContext } from '../../contexts/FilterContext'
import { SendAlertProps, FormValues } from './interfaces'
import Checkbox from './Checkbox'

export const NotifyForm = (props: SendAlertProps & FormikProps<FormValues>) => {
  const { values, handleChange, handleBlur, handleSubmit, isSubmitting } = props

  const { organizations } = React.useContext(FilterContext)

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit}>
        <Label>{strings.incidentDetail.sendMessageForm.label}</Label>

        <Label>{strings.incidentDetail.sendMessageForm.organization}</Label>
        <Select
          name="organization"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.organization}
          disabled={isSubmitting}
        >
          <option value="">Select</option>
          {(organizations || []).map(org => (
            <option key={org.id.toString()} value={org.id.toString()}>
              {org.name}
            </option>
          ))}
        </Select>
        <ErrorMessage component={RedMessage} name="organization" />

        <Label>{strings.incidentDetail.sendMessageForm.title}</Label>
        <Input
          name="title"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.title}
          placeholder={strings.incidentDetail.sendMessageForm.title}
          disabled={isSubmitting}
        />
        <ErrorMessage component={RedMessage} name="title" />

        <Label>{strings.incidentDetail.sendMessageForm.message}</Label>
        <Textarea
          name="body"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.body}
          placeholder={strings.incidentDetail.sendMessageForm.placeholder}
          disabled={isSubmitting}
        />
        <ErrorMessage component={RedMessage} name="body" />

        <CheckboxContainer>
          <Checkbox name="sendToOrganizationUsers">
            {strings.incidentDetail.sendMessageForm.sendToOrganizationUsers}
          </Checkbox>

          <Checkbox name="sendOnlyOrganizationArea">
            {strings.incidentDetail.sendMessageForm.sendOnlyOrganizationArea}
          </Checkbox>
        </CheckboxContainer>

        <SendButton type="submit" disabled={isSubmitting}>
          {strings.incidentDetail.sendMessageForm.button}
        </SendButton>
      </Form>
      <CancelButton onClick={props.onHideForm}>
        {strings.incidentDetail.buttons.cancel}
      </CancelButton>
    </Wrapper>
  )
}
