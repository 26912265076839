import React from 'react';
import styled from 'styled-components/macro';
import { colors } from '../../../constants';

interface IButtonProps {
  disabled: boolean;
}

export const SendButton: React.FC<IButtonProps> = ({ disabled }) => {
  return <Button type="submit" disabled={disabled}>Send</Button>;
};

const Button = styled.button`
  background: ${colors.background};
  color: ${colors.white};
  padding: 8px 10px;
  outline: none;
  border: none;
  padding: 5px;
  width: 20%;
`;
