import * as React from 'react';
import styled from 'styled-components/macro';
import { fonts, colors } from '../../../constants';

interface IErrorMessageProps {
  message: string;
  isError: boolean;
}

export const ErrorMessage = (props: IErrorMessageProps) => {
  const { message, isError } = props;
  const shownMessage = isError ? message : ""
  return (
    <Container isMessageShow={isError}>
      <Message>{shownMessage}</Message>
    </Container>
  );
};

interface IContainerProps {
  isMessageShow: boolean;
}

const Container = styled.div`
  display: flex;
  margin-bottom: ${(props:IContainerProps) => props.isMessageShow ? '10px' : '36px'};
  justify-content: center;
`;

const Message = styled.span`
  color: ${colors.darkRed};
  font-family: ${fonts.defaultFont};
  font-weight: bold;
  font-size: 19px;
  line-height: 26px;
  letter-spacing: -0.1px;
`