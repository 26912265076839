import { AccessToken, AccessTokenImpl, OrganizationUser } from "incident-code-core"
import { parseJwt } from "../utils/jwt-utils"

export const KeyToken = "AccessToken"

interface IAppSetting {
  readonly accessToken: AccessToken
}

class AppSetting implements IAppSetting {
  accessToken: AccessToken = new AccessTokenImpl()
  currentUser: OrganizationUser

  constructor() {
    const token = this.getValue(KeyToken)
    if (token) {
      this.setAccessToken(token)
    }
  }

  get isLoggedIn() {
    if (this.accessToken && this.accessToken.access_token) {
      return this.accessToken.isValid
    }
    return false
  }

  // Access Token
  public setAccessToken(token: AccessToken) {
    if (token !== null) {
      if (token.expires_at === null && token.expires_in) {
        token.expires_at = Date.now() * token.expires_in * 1000
      }

      Object.assign(this.accessToken, token)

      this.currentUser = parseJwt(token.access_token)
    } else {
      this.currentUser = null
    }

    this.setValue(KeyToken, token)
  }

  // Local Storage
  private setValue(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value))
  }
  public getValue(key: string) {
    const value = localStorage.getItem(key)

    return value ? JSON.parse(value) : null //TODO: parse with date
  }

  private removeValue(key: string): void {
    localStorage.removeItem(key)
  }

  public clearStorage() {
    this.accessToken = new AccessTokenImpl()
    this.currentUser = null
    localStorage.clear()
  }
}

export const appSetting = new AppSetting()
