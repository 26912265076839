import React, { useState, useEffect } from 'react'

import { incidentService } from '../services/incident-service'
import styled from 'styled-components'
import { historyService } from '../services/history-service'
import { HistoryType, ChatActionType } from 'incident-code-core'

const PublicCodeLabel = styled.label`
  font-size: 18px;
  margin-bottom: 20px;
`

const PublicCodeForm = styled.form`
  display: flex;
  justify-content: space-between;
`

const StyledCheckBox = styled.input`
  transform: scale(2);
  -ms-transform: scale(2);
  -webkit-transform: scale(2);
  padding: 10px;
`

const GeneratePublicCodeCheckbox = (props: any) => {
  const [checked, setChecked] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const handleCheck = async () => {
    const [, { data }] = await Promise.all([
      historyService.create({
        incidentId: `${props.incident.id}`,
        type: HistoryType.Chat,
        action: 'Incident Code Generated' as ChatActionType
      }),
      incidentService.generatePublicCode(props.incident.id)
    ])

    props.handleData(data)
    setChecked(!checked)
    setDisabled(true)
  }

  useEffect(() => {
    if (props.incident.publicCode) {
      setChecked(true)
      setDisabled(true)
    }
  }, [props.incident.publicCode])

  return (
    <PublicCodeForm>
      <PublicCodeLabel htmlFor="generatePublicCode">
        Generate IncidentCODE Public Code:
      </PublicCodeLabel>
      <StyledCheckBox
        type="checkbox"
        id="generatePublicCode"
        name="id"
        value={props.incident.id}
        onChange={handleCheck}
        checked={checked}
        disabled={disabled}
      />
    </PublicCodeForm>
  )
}

export { GeneratePublicCodeCheckbox }
