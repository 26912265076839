import * as React from 'react'
import { IncidentEscortState } from 'incident-code-core'
import styled from 'styled-components/macro'
import { colors, fonts, strings } from '../../../../constants'
import { renderPassedTime } from '../../../../utils/dateUtils'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'

interface ITitleProps {
  index: string
  organization: string
  createdAt?: Date
  escort?: string,
  type?: string
}

export const Title = (props: ITitleProps) => {
  const { escort, index, organization, createdAt, type } = props
  const key = useSelector((state: RootState) => state.appData.timestamp)
  let typeLabel = null;

  if (type === "passiveEscort") {
    typeLabel = "Passive Escort"
  } else if (type === "activeEscort") {
    typeLabel = "Active Escort"
  }
  return (
    <TitleContainer>
      <DescriptionBox>
  <IncidentNumber>#{index} {typeLabel}</IncidentNumber>
        <OrganizationName>{organization}</OrganizationName>
      </DescriptionBox>
      <TimeBox>
        <IncidentTitle>{strings.incidentDetail.header.reported}</IncidentTitle>
        <Time key={key}>{renderPassedTime(createdAt)}</Time>
        {escort === IncidentEscortState.Active && <EscortMark>ESCORT</EscortMark>}
      </TimeBox>
    </TitleContainer>
  )
}

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 15px 0;
  z-index: 2;
`

const DescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
`

const IncidentNumber = styled.div`
  color: ${colors.white};
  font-family: ${fonts.semiBold};
  font-size: 16px;
  letter-spacing: 0.4px;
`

const OrganizationName = styled.div`
  color: ${colors.white};
  font-size: 16px;
`

const TimeBox = styled.div`
  align-items: flex-end;
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  width: 40%;
`

const IncidentTitle = styled.span`
  font-size: 12px;
  letter-spacing: -0.2px;
  opacity: 0.6;
  text-align: right;
`

const Time = styled.span`
  font-size: 12px;
  letter-spacing: -0.2px;
  text-align: right;
`

const EscortMark = styled.div`
  align-items: center;
  background-color: ${colors.secondary};
  border-radius: 2px;
  color: ${colors.ghostWhite};
  display: flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  margin-top: 16px;
  padding: 0 8px;
  text-transform: uppercase;
`
