import React from 'react';

interface Props {
  title: string;
  message: string;
}

export const ChatNotification: React.FC<Props> = ({ title, message }) => (
  <div>
    <h4>{title}</h4>
    <p>{message}</p>
  </div>
)
