import * as React from 'react'
import { AppUser, IncidentEscortState, IncidentEscort } from 'incident-code-core'
import styled from 'styled-components/macro'
import { colors, fonts } from '../../../../constants'
import {EscortCardStateLabel} from "./Label"
import { USPhoneFormat } from '../../../../utils/formatPhoneNumbers'

interface IInfoProps {
  createdBy: AppUser
  type?: string
  isUnresolved?: boolean
  address?: string
  createdAt?: Date
  state?: string
  isOutOfZone?: boolean
  admin?: string
  escortState?: IncidentEscort
  isPanic?: boolean
}

export const Info: React.FC<IInfoProps> = ({
  createdBy: { firstName, lastName, phone },
  isUnresolved,
  address,
  type,
  createdAt,
  state,
  isOutOfZone,
  admin,
  escortState,
  isPanic
}) => {

  return (
    <InfoContainer>
      {isOutOfZone && <OutOfZoneMark>Out of zone</OutOfZoneMark>}
      {escortState?.state === IncidentEscortState.Panic && <PanicBox />}
      {escortState?.state === IncidentEscortState.Panic && (
        <PanicMark isOutOfZone={isOutOfZone}>PANIC MODE</PanicMark>
      )}
      {isUnresolved && <UnresolvedMark>New</UnresolvedMark>}
      <CreaterName>
        {firstName} {lastName}
      </CreaterName>
      <CreaterPhone>{USPhoneFormat(phone)}</CreaterPhone>
      <EscortCardStateLabel type={type} state={escortState?.state} timerExpired={escortState?.fromTimerExpired} isPanic={isPanic} />
      <IncidentLocation>{address || ''}</IncidentLocation>
      {admin && <LastAccessBy>{admin}</LastAccessBy>}
    </InfoContainer>
  )
}

const InfoContainer = styled.div`
  background-color: ${colors.midGray4};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 170px;
  padding: 20px 15px 15px;
  position: relative;
  z-index: 2;
`

const CreaterName = styled.span`
  color: ${colors.white};
  font-family: ${fonts.semiBold};
  font-size: 18px;
`

const CreaterPhone = styled.span`
  color: ${colors.ghostWhite};
  opacity: 0.9;
  font-family: ${fonts.light};
  font-size: 16px;
`

const IncidentLocation = styled.p`
  color: ${colors.ghostWhite};
  font-size: 13px;
  margin-bottom: 0;
  margin-top: auto;
  opacity: 0.4;
`

const OutOfZoneMark = styled.div`
  align-items: center;
  background-color: ${colors.primary};
  border-radius: 16px;
  color: ${colors.ghostWhite};
  display: flex;
  font-size: 12px;
  height: 32px;
  justify-content: center;
  padding: 0 12px;
  position: absolute;
  right: 10px;
  top: -16px;
`

const PanicMark = styled.div<{ isOutOfZone: boolean }>`
  align-items: center;
  background-color: ${colors.secondary};
  border-radius: 16px;
  color: ${colors.ghostWhite};
  display: flex;
  font-size: 12px;
  height: 32px;
  justify-content: center;
  padding: 0 12px;
  position: absolute;
  right: ${props => (props.isOutOfZone ? 110 : 10)}px;
  text-transform: uppercase;
  top: -16px;
`

const PanicBox = styled.div`
  &::before {
    content: '';
    background: ${colors.secondary};
    display: block;
    height: 35px;
    left: 0;
    opacity: 0.5;
    position: absolute;
    right: 0;
    top: -35px;
    width: 100%;
    z-index: -1;
  }

  &::after {
    content: '';
    background: ${colors.secondary};
    display: block;
    height: 60px;
    left: 0;
    opacity: 0.5;
    position: absolute;
    right: 0;
    top: -60px;
    width: 100%;
    z-index: -1;
  }
`

const LastAccessBy = styled.div`
  align-items: center;
  background-color: ${colors.midGray3};
  border-radius: 2px;
  bottom: -12px;
  color: ${colors.ghostWhite};
  display: flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  padding: 0 12px;
  position: absolute;
  right: 10px;
  text-transform: uppercase;
`
const UnresolvedMark = styled.div`
  align-items: center;
  background-color: ${colors.secondary};
  border-radius: 16px;
  color: ${colors.ghostWhite};
  display: flex;
  font-size: 12px;
  height: 32px;
  justify-content: center;
  padding: 0 12px;
  position: absolute;
  right: 10px;
  top: -16px;
`
