import React from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { strings } from '../../constants'
import { FilterContext } from '../../contexts/FilterContext'
import { incidentActions } from '../../store'
import { useDispatch } from 'react-redux'

interface IDropDownProps {}

export const OrganizationsDropdown: React.FC<IDropDownProps> = () => {
  const dispatch = useDispatch();
  const setFilter = (orgId: string) => dispatch(incidentActions.setFilters({
    orgId
  }))
  const { organizations, selectedOrganization, setOrganization } = React.useContext(FilterContext)

  const [dropdownOpen, setDropdownOpen] = React.useState(false)

  const toggle = () => setDropdownOpen(prevState => !prevState)

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret color="light">
        {selectedOrganization ? selectedOrganization.name : strings.allOrganizations}
      </DropdownToggle>
      <DropdownMenu color="light">
        <DropdownItem onClick={() => {setOrganization(null); setFilter(null)}}>
          {strings.allOrganizations}
        </DropdownItem>
        {(organizations || []).map(org => (
          <DropdownItem key={org.id.toString()} onClick={() => {setOrganization(org); setFilter(org.id.toString())}}>
            {org.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}
