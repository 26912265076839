import styled from 'styled-components/macro';

export interface IButton {
  sideSize: number;
  isPlaying: boolean;
}

export const Button = styled.button`
  display: ${(props: IButton) => (props.isPlaying ? 'none' : 'block')};
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  width: ${(props: IButton) => props.sideSize}px;
  height: ${(props: IButton) => props.sideSize}px;
  border: 0;
  border-radius: 14px;
  background-color: rgba(25, 27, 35, 0.86);
`;


interface IPlayerContainerProps {
  fullwidth: boolean
}

export const PlayerContainer = styled.div`
  width: ${(props: IPlayerContainerProps) => props.fullwidth ? '100%' : '50%'};
  max-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    .player-btn {
      display: block;
    }
  }
`;

export const VideoPreview = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  margin-right: 10px;
`