import * as React from 'react'
import styled from 'styled-components/macro'
import { colors } from '../../constants'
import { ChatMessage } from 'incident-code-core'
import { getCreatedAtString } from '../../utils/dateUtils'

interface IBoxProps {
  message: ChatMessage
  right: Boolean
}

type StyledBoxMessageProps = { right: Boolean }

export const Box: React.FC<IBoxProps> = ({ message, right }) => (
  <Container>
    <BoxMessage right={right}>
      <Content>
        <Text>{message.content}</Text>
        <BoxCreatedAt>{getCreatedAtString(message.createdAt)}</BoxCreatedAt>
        {message.location && <BoxLocation>{message.location.coordinates}</BoxLocation>}
        {message.address && <BoxAddress>{message.address}</BoxAddress>}
      </Content>
    </BoxMessage>
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-bottom: 3px;
`
const BoxMessage = styled.div`
  display: flex;
  color: ${(props: StyledBoxMessageProps) => (props.right ? colors.ghostWhite : colors.darkGray)};
  background: ${(props: StyledBoxMessageProps) =>
    props.right ? colors.primary : colors.lightGray};
  justify-content: ${(props: StyledBoxMessageProps) => (props.right ? 'flex-end' : 'flex-start')};
  padding: 15px;
  border-radius: ${(props: StyledBoxMessageProps) =>
    props.right ? '20px 20px 0 20px' : '20px 20px 20px 0'};
  max-width: 80%;
  margin: ${(props: StyledBoxMessageProps) => (props.right ? '0 0 0 auto' : '0 auto 0 0')};
`
const Content = styled.div`
  flex-direction: row;
`
const Text = styled.div`
  font-size: 20px;
  justify-content: center;
`
const BoxCreatedAt = styled.div`
  font-size: 14px;
  justify-content: center;
`
const BoxLocation = styled.div`
  font-size: 10px;
  justify-content: center;
`
const BoxAddress = styled.div`
  font-size: 10px;
  justify-content: center;
`
