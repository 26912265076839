import * as Yup from 'yup';
import { HistoryType } from 'incident-code-core';
import { withFormik } from 'formik'

import { historyService } from '../../services/history-service'
import { AddNoteProps, FormValues } from './interfaces'
import AddNoteForm from './Form'


const addNoteSchema = Yup.object().shape({
  comment: Yup.string().trim().required(),
});

export const AddNote = withFormik<AddNoteProps, FormValues>({
  mapPropsToValues: () => ({ title: "", comment: "" }),
  validationSchema: addNoteSchema,
  handleSubmit({ comment }: FormValues, { props, setSubmitting, resetForm }) {
    const payload = {
      incidentId : props.incidentId,
      type: HistoryType.Note,
      content: comment,
    }
    historyService.create(payload).then(() => {
      props.onHideForm();
      resetForm({})
    })

    setSubmitting(false)
  }
})(AddNoteForm);