import { configureStore, createAction, combineReducers } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import incidents from './incidents'
import firebase from './firebase'
import appData from './appData'
import appDataRootSaga from './appData/sagas'

import incidentSaga from './incidents/sagas'
import { all, fork } from 'redux-saga/effects'
import firebaseRootSaga from './firebase/sagas'
import { RootStateOrAny } from 'react-redux'
import { notificationReducer, notificationSaga, notificationActions, notificationSelectors } from './notifications'

export { incidentActions } from './incidents'
export { firebaseActions } from './firebase'
export { notificationActions, notificationSelectors }



const sagaMiddleware = createSagaMiddleware()

export const resetAction = createAction('RESET_STATE')

const appReducer = combineReducers({
  appData,
  firebase,
  incidents,
  notifications: notificationReducer
})

const rootReducer = (state: RootStateOrAny, action: typeof resetAction) => {
  if (resetAction.match(action)) state = undefined

  return appReducer(state, action)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware]
})

function* rootSaga() {
  yield all([
    fork(appDataRootSaga),
    fork(firebaseRootSaga),
    fork(incidentSaga),
    fork(notificationSaga)
  ])
}

sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>

export default store
