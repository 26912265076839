import { MediaLink } from "incident-code-core"

type IPlayNext = () => void

export class MediaLinkPlayer {
  private currentIndex: number = 0
  private readonly listVideos: MediaLink[]

  constructor(videoList: MediaLink[]) {
    this.listVideos = videoList
  }

  public setCurrentVideoById = (sid: string) => {
    const videoIndex = this.listVideos.findIndex(video => video.sid === sid)

    if (videoIndex || videoIndex === 0) {
      this.currentIndex = videoIndex
    }
  }

  // CONTROLS
  public next = () => {
    const isLastVideo = this.currentIndex === this.listVideos.length - 1
    if (isLastVideo) {
      this.currentIndex = 0
    } else {
      this.currentIndex = this.currentIndex + 1
    }
  }

  //GETTERS
  get currentVideo(): MediaLink {
    return this.listVideos[this.currentIndex]
  }
  get allVideos(): MediaLink[] {
    return this.listVideos
  }
  public getCurrentVideoID = (): string => {
    return this.currentVideo.sid
  }
  public getCurrentIndex = (): number => {
    return this.currentIndex
  }
}
