import * as React from 'react'
import styled from 'styled-components/macro'
import { colors, fonts, strings } from '../../../constants'

interface ILoginInputsProps {
  login: string | null
  password: string | null
  changeLogin(newLogin: string): void
  changePassword(newPassword: string): void
}

export class LoginInputs extends React.Component<ILoginInputsProps> {
  render() {
    const { emailPlaceholder, passwordPlaceholder } = strings.loginScreen
    const login = this.props.login || ''
    const password = this.props.password || ''

    return (
      <Container>
        <Input
          placeholder={emailPlaceholder}
          type="email"
          onChange={this.onLoginChange}
          value={login}
        />
        <Input
          placeholder={passwordPlaceholder}
          type="password"
          onChange={this.onPasswordChange}
          value={password}
        />
      </Container>
    )
  }
  private onLoginChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target
    this.props.changeLogin(value)
  }
  private onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target
    this.props.changePassword(value)
  }
}

const Container = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 50px;
`

const Input = styled.input`
  background-color: ${colors.ghostWhite};
  border-radius: 5px;
  border: none;
  color: ${colors.black};
  font-family: ${fonts.defaultFont};
  font-size: 20px;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;

  &::-webkit-input-placeholder {
    color: ${colors.midnight};
  }

  &:first-child {
    margin-bottom: 10px;
  }
`
