import {
  WallIncident,
  IncidentChat,
  ChatMessage,
  IncidentType,
  IncidentLocation,
  IncidentLocationRecord,
  IncidentVideo,
  LatLng
} from 'incident-code-core'
import React, { useEffect, Fragment, useState, RefObject } from 'react'
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
  InfoWindow,
  Polyline
} from 'react-google-maps'
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer'
import { scroller } from 'react-scroll'
import { compose, withProps } from 'recompose'
import styled from 'styled-components/macro'
import moment from 'moment'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import { images } from '../../../../../constants'
import { Env } from '../../../../../constants/env'
import { INCIDENT_SCROLL_PARAMS } from '../../../../../constants/scroll'
import { FetchIncidentsEvent, IncidentListener } from '../../../../../services/incident-service'
import {
  FetchIncidentsEvent as FetchEscortEvent,
  IncidentListener as EscortListener
} from '../../../../../services/escort-service'

import { customMarker, circleMarker, squareMarker } from '../../../../../utils/custom-marker'
import { IRegion, mapUtils } from '../../../../../utils/map-utils'
import './map.css'

interface IMapProps {
  incidents: WallIncident[]
  shownId: null | string
  showMessages?: boolean
  showVideos?: boolean
  chat?: IncidentChat
  type: IncidentType
  escortLocations?: IncidentLocation
}

const MAP_URL = `https://maps.googleapis.com/maps/api/js?key=${Env.googleClientKey}&v=3.exp&libraries=geometry,drawing,places`

const MARKER_CLUSTER_STYLE = {
  textColor: 'white',
  url: images.pinCluster,
  height: 85,
  width: 85
}

const DEFAULT_CENTER = { lat: 39.598845, lng: -105.123053 }
const DEFAULT_ZOOM = 13
const CLUSTERIZATION_ENABLED_ZOOM = 21

const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (min-width: 1024.1px) and (max-width: 1280px) {
    width: 100%;
  }
  @media (min-width: 1280.1px) {
    width: 100%;
  }
`

interface ReportChatMassage extends ChatMessage {
  type?: string
}

interface Content {
  id: string
  descriptions: string[]
  lat: number
  lng: number
}

interface IMapState {
  incidents: WallIncident[]
  center: IRegion | null
  zoom: number | null
  wasDragged?: boolean
}

interface IEscortLocationPair {
  color?: string
  locations: LatLng[]
}

const initialState = {
  incidents: [] as WallIncident[],
  center: { lng: 0, lat: 0 },
  zoom: 15,
  wasDragged: false
}

function RenderMap(props: IMapProps) {
  const [state, setState] = useState<IMapState>(initialState as IMapState)
  const [activeInfo, setActiveInfo] = useState<string>()
  const [messagesContent, setMessagesContent] = useState<Content[]>([])
  const [videosContent, setVideosContent] = useState<Content[]>([])
  const [escortLocation, setEscortLocation] = useState<IncidentLocationRecord>()
  const [escortLocations, setEscortLocations] = useState<IEscortLocationPair[]>()

  let mapRef: RefObject<GoogleMap> = React.createRef()

  const updateStateForEscorts = (props: any) => {
    if (!!props.incidents) {
      const { incidents, escortLocations } = props

      if (!!escortLocations && escortLocations.locations.length > 0) {
        const { zoom } = mapUtils.getInitialRegion(incidents)
        const last = escortLocations.locations[escortLocations.locations.length - 1].location
        setState(prev => ({
          incidents,
          center: prev.wasDragged ? prev.center : last,
          zoom: zoom,
          wasDragged: prev.wasDragged ? true : false
        }))
      } else {
        const { center, zoom } = mapUtils.getInitialRegion(incidents)
        setState(prev => ({
          incidents,
          center: prev.wasDragged ? prev.center : center,
          zoom: zoom,
          wasDragged: prev.wasDragged ? true : false
        }))
      }
    }
  }

  useEffect(() => {
    if ([IncidentType.Escort, IncidentType.PassiveEscort].includes(props.type)) {
      updateStateForEscorts(props)

      EscortListener.on(FetchEscortEvent, (data: any) => {
        const { data: incidents } = data
        if (!props.shownId) {
          updateStateForEscorts(incidents)
        }
      })
    } else {
      updateState(props.incidents)
      IncidentListener.on(FetchIncidentsEvent, (data: any) => {
        const { data: incidents } = data
        if (!props.shownId) {
          updateState(incidents)
        }
      })
    }

    return function cleanup() {
      if (props.type === IncidentType.Escort) {
        EscortListener.removeAllListeners(FetchEscortEvent)
      } else {
        IncidentListener.removeAllListeners(FetchIncidentsEvent)
      }
    }
    // eslint-disable-next-line
  }, [props.shownId, props.type, props.incidents, props.escortLocations])

  useEffect(() => {
    let videos: IncidentVideo[] = []

    if (props.incidents && props.incidents.length > 0) {
      videos = props.incidents[0].videos
    }

    if (videos && videos.length > 0) {
      const group = groupBy(
        videos,
        v => v.location.coordinates[0].toString() + v.location.coordinates[1].toString()
      )

      let contents: Content[] = []
      Object.keys(group).forEach(key => {
        const [lng, lat] = group[key][0].location.coordinates

        contents.push({
          id: group[key][0].id.toString(),
          descriptions: group[key].map(d => d.uploadId.toString()),
          lat: lat,
          lng: lng
        })
      })

      setVideosContent(contents)
    }
  }, [props.incidents])

  useEffect(() => {
    if (props.chat) {
      const chatMessages = props.chat.messages as ReportChatMassage[]
      const myMessages = chatMessages.filter(m => m.type === 'message' && m.from.charAt(0) === 'U')
      const group = groupBy(
        myMessages,
        m => m.location.coordinates[0].toString() + m.location.coordinates[1].toString()
      )

      let contents: Content[] = []
      Object.keys(group).forEach(key => {
        const [lng, lat] = group[key][0].location.coordinates

        contents.push({
          id: group[key][0].id,
          descriptions: group[key].map(d => moment(d.createdAt).format('MM/DD/YYYY LT')),
          lat: lat,
          lng: lng
        })
      })

      setMessagesContent(contents)
    }
  }, [props.chat])

  useEffect(() => {
    if (props.escortLocations) {
      const { locations } = props.escortLocations

      const orderedLocations = orderBy(locations, l => l.timestamp, ['desc'])

      if (orderedLocations && orderedLocations.length > 0) {
        setEscortLocation(orderedLocations[0])

        let arrayOfEscortLocations: IEscortLocationPair[] = []
        let contents: Content[] = []

        // eslint-disable-next-line
        orderedLocations.map((el: IncidentLocationRecord, i: number) => {
          let locations: LatLng[] = []
          locations.push(el.location)

          if (orderedLocations.length >= i + 2) {
            locations.push(orderedLocations[i + 1].location)
          }

          const color = getColor(el)
          arrayOfEscortLocations.push({ color, locations })

          if (el.isVideoRecEnabled) {
            const addVideo = (record: IncidentLocationRecord) => {
              contents.push({
                id: i.toString(),
                descriptions: [moment(record.addedAt).format('MM/DD/YYYY LT')],
                lat: record.location.lat,
                lng: record.location.lng
              })
            }

            if (i === orderedLocations.length - 1 || !orderedLocations[i + 1].isVideoRecEnabled) {
              addVideo(el)
            }
          }
        })

        setVideosContent(contents)

        setEscortLocations(arrayOfEscortLocations)
      }
    }
  }, [props.escortLocations])

  const getColor = (el: IncidentLocationRecord) => {
    if (el.isPanic) {
      return '#ce0942'
    }

    if (el.isVideoRecEnabled) {
      return '#dc8c09'
    }

    if (el.isAudioRecEnabled) {
      return '#048f4f'
    }

    return '#000000'
  }

  const updateState = (incidents: WallIncident[]) => {
    let nextState: IMapState = {
      incidents,
      center: state.wasDragged ? state.center : null,
      zoom: null,
      wasDragged: state.wasDragged
    }
    if (incidents.length) {
      const { center, zoom } = mapUtils.getInitialRegion(incidents)
      nextState.center = state.wasDragged ? state.center : center
      nextState.zoom = zoom
      nextState.wasDragged = state.wasDragged
    }
    setState(nextState)
  }

  const toogleInfoWindow = (id: string) => {
    if (activeInfo === id) {
      setActiveInfo(null)
      return
    }
    setActiveInfo(id)
  }

  const handleDragEnd = () => {
    const { lat, lng } = mapRef.current.getCenter()
    const mapCenter = { lat: lat(), lng: lng() }
    setState({ ...state, center: mapCenter, wasDragged: true })
  }

  const isSingle = props.incidents.length === 1 && props.shownId
  const { incidents, center, zoom } = state

  return (
    <GoogleMap
      zoom={zoom || DEFAULT_ZOOM}
      center={center || DEFAULT_CENTER}
      ref={mapRef}
      onDragEnd={handleDragEnd}
      options={{
        streetViewControl: false,
        fullscreenControl: false
      }}
    >
      <MarkerClusterer
        clusterClass="cluster"
        styles={[MARKER_CLUSTER_STYLE]}
        maxZoom={CLUSTERIZATION_ENABLED_ZOOM}
      >
        {incidents.map((incident: WallIncident, id: number) => {
          if (!incident.location || !incident.location.coordinates) {
            return undefined
          }
          const scrollToIncident = () => {
            scroller.scrollTo(incident.id.toString(), INCIDENT_SCROLL_PARAMS)
          }
          const [longitude, latitude] = incident.location.coordinates
          const markerTitle = isSingle || id + 1
          return (
            <Fragment key={id}>
              <Marker
                onClick={scrollToIncident}
                position={{ lat: latitude, lng: longitude }}
                icon={{
                  url: customMarker(`${markerTitle}`)
                }}
              />

              {props.showVideos &&
                videosContent &&
                videosContent.map((v: Content, i) => {
                  return (
                    <Marker
                      onClick={() => toogleInfoWindow(v.id.toString())}
                      key={i}
                      position={{ lat: v.lat, lng: v.lng }}
                      icon={{ url: circleMarker() }}
                    >
                      {v.id.toString() === activeInfo && (
                        <InfoWindow onCloseClick={() => toogleInfoWindow(v.id.toString())}>
                          <div>
                            <div className="mb-2">
                              <strong>Video shared:</strong>
                            </div>
                            {v.descriptions.map(v => (
                              <div key={v}>#{v}</div>
                            ))}
                          </div>
                        </InfoWindow>
                      )}
                    </Marker>
                  )
                })}

              {props.showMessages &&
                messagesContent &&
                messagesContent.map((m: Content) => {
                  return (
                    <Marker
                      onClick={() => toogleInfoWindow(m.id.toString())}
                      key={m.id.toString()}
                      position={{ lat: m.lat, lng: m.lng }}
                      icon={{ url: squareMarker() }}
                    >
                      {m.id.toString() === activeInfo && (
                        <InfoWindow onCloseClick={() => toogleInfoWindow(m.toString())}>
                          <div>
                            <div className="mb-2">
                              <strong>Message shared at</strong>
                            </div>
                            {m.descriptions.map(d => (
                              <div key={d}> - {d}</div>
                            ))}
                          </div>
                        </InfoWindow>
                      )}
                    </Marker>
                  )
                })}

              {escortLocations &&
                escortLocations.map((el, i) => (
                  <Polyline key={i} path={el.locations} options={{ strokeColor: el.color }} />
                ))}

              {escortLocation && <Marker position={escortLocation.location} />}
            </Fragment>
          )
        })}
      </MarkerClusterer>
    </GoogleMap>
  )
}

export const Map: any = compose(
  withProps({
    googleMapURL: MAP_URL,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <MapContainer />,
    mapElement: <div id="map" style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(RenderMap)
