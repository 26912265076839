import * as React from 'react';
import { RouteProps, RouteComponentProps, Route, Redirect } from 'react-router';
import { appSetting } from '../../services/app-setting';
import { ROUTES } from '../../constants'

interface PrivateRouteProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

export const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        appSetting.isLoggedIn ? ( 
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.login
            }}
          />
        )
      }
    />
  );
};