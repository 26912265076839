import { AxiosResponse } from "axios"
import {
  Id,
  WallHistoryRequest,
  WallHistoryResult
} from "incident-code-core"

import { ApiService } from "./api-service"


interface IHistoryService {
  create(payload: WallHistoryRequest): Promise<AxiosResponse>
  getHistoryByIncidentId(incidentId: Id): Promise<AxiosResponse>
}

class HistoryService extends ApiService implements IHistoryService {
  public create = async (payload: WallHistoryRequest): Promise<AxiosResponse<WallHistoryResult>> => {
    const url = ["wall", "history"]
    return await this.post(url, payload)
  }

  public getHistoryByIncidentId = async (incidentId: Id): Promise<AxiosResponse<WallHistoryResult>> => {
    const url = ["wall", "history", incidentId]
    return await this.get(url)
  }

}

export const historyService = new HistoryService()
