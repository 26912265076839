export const strings = {
  logo: {
    title: 'Incident',
    subtitle: 'wall'
  },
  header: {
    total: 'reports',
    logOut: 'Logout',
    lastHours: 'in the last 2 hours',
    myAccount: 'My Account'
  },
  loginScreen: {
    emailPlaceholder: 'Your company email',
    passwordPlaceholder: 'Password',
    loginButton: 'Login',
    resetButton: 'Reset password',
    blockToUse: 'Your Provider cannot use Incident Wall'
  },
  loginErrorMessage: 'Please enter a correct login and password',
  genericApiError: 'Some error occurred. Please contact the adminstrator.',
  incidentDetail: {
    header: {
      incident: 'Incidents',
      reported: 'Reported '
    },
    detailTitles: {
      contact: 'Contact',
      comment: 'Comment',
      noComment: 'No Comment',
      incidentType: 'Incident type',
      date: 'Date and time',
      address: 'Nearby address',
      uploadedVideos: 'Uploaded videos',
      creator: ''
    },
    buttons: {
      resolve: 'Close incident',
      link: 'Share incident',
      cancel: 'Cancel',
      notifyUsers: 'Notify users',
      seeReport: 'See report',
      addNote: 'Add note'
    },
    generateLink: {
      label: 'Share a link to this incident using email or SMS',
      placeholder: 'email / phone number',
      button: 'Share'
    },
    sendMessageForm: {
      label:
        'Send a notification to users who are using the Incident Go app and are in the immediate area',
      organization: 'Select organization',
      organizationArea: 'Select organization area',
      title: 'Title',
      message: 'Message',
      placeholder: 'Your message',
      button: 'Send',
      sendToOrganizationUsers: 'Send to all Organization users',
      sendOnlyOrganizationArea: 'Send only to users in Organization immediate zone'
    },
    addNoteForm: {
      header: 'Add a note to this incident',
      label: 'Add any text note to this incident history',
      placeholder: 'Type your note here'
    },
    activeIncident: 'Active incident',
    resolvedIncident: 'Close incident'
  },
  escortDetail: {
    pending: 'Pending',
    active: 'Active',
    panic: 'Panic',
    background: 'Background',
    unknown: 'Unknown',
    closed: 'Closed',
    safe: 'Safe',
    expired: 'Expired',
    canceled: 'Canceled'
  },
  noIncidents: 'There are no current incidents reported',
  loading: 'Loading...',
  organization: 'Organization',
  allOrganizations: 'All Organizations',
  tips: 'Tips',
  escorts: 'Escorts',
  users: 'Users',
  active: 'Active',
  closed: 'Closed'
}
