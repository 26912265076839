import React from 'react'
import styled from 'styled-components/macro'
import { AppUser } from 'incident-code-core'
import { images } from '../constants'

interface IconStyleProps {
  readonly height?: number
  readonly width?: number
  readonly margin?: number | string
}

interface IconProps extends IconStyleProps {
  user?: AppUser
}

const UserIconFrame = styled.div<IconStyleProps>`
${props => (props.margin ? `margin: ${props.margin};` : '')}
height: ${props => (!!props.height ? props.height + 'px' : '100%')};
width: ${props => (!!props.width ? props.width + 'px' : 'auto')};
overflow: hidden;
`

const ScaledUserImage = styled.img`
  height: 100%;
`

const UserIcon: React.FC<IconProps> = props => {
  const { height, width, user, margin } = props
  const iconSrc = user.portraitUrl
    ? user.portraitUrl + '?time=' + new Date().getTime().toString()
    : images.blankPerson
  return (
    <UserIconFrame height={height} width={width} margin={margin}>
      <ScaledUserImage src={iconSrc} alt="User Profile Portrait" />
    </UserIconFrame>
  )
}

export { UserIcon }
