import React from 'react';
import styled from 'styled-components/macro';
import { images } from "../../../../../constants";

interface IPlayButton {
  isPlaying: boolean;
  sideSize: number;
  onClick(): void;
}

export const PlayButton: React.FC<IPlayButton> = ({ isPlaying, sideSize, onClick }) => {
  const image = isPlaying ? images.ic_pause : images.ic_play;
  return (
    <Button
      sideSize={sideSize}
      onClick={onClick}
      isPlaying={isPlaying}
      className="player-btn"
    >
      <img src={image} alt="control" />
    </Button>
  );
};

interface IButton {
  sideSize: number;
  isPlaying: boolean;
}
const Button = styled.button`
  display: ${(props: IButton) => (props.isPlaying ? 'none' : 'block')};
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  width: ${(props: IButton) => props.sideSize}px;
  height: ${(props: IButton) => props.sideSize}px;
  border: 0;
  border-radius: 14px;
  background-color: rgba(25, 27, 35, 0.86);
`;
