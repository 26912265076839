import styled from 'styled-components/macro';
import { colors } from '../../constants';

interface StyledButtonProps {
  isGradient: boolean;
  className?: string;
};

export const Button = styled.button`
  background: ${(props: StyledButtonProps) =>
    props.isGradient ? colors.linearGradient : colors.background};
  color: ${colors.white};
  flex: 1;
  padding: 8px 10px;
  outline: none;
  border: none;
  font-size: 16px;
  letter-spacing: -0.2px;
  text-align: center;
  margin: 0 5px;
  &:first-child {
    margin-left: 0;
    margin-right: 5px;
  };
  &:last-child {
    margin-left: 5px;
    margin-right: 0;
  }
`;
