import * as React from 'react'
import styled from 'styled-components/macro'
import { colors, fonts } from '../../../../constants'
import { renderPassedTime } from '../../../../utils/dateUtils'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'

interface ITitleProps {
  index: string
  title: string
  organization: string
  createdAt?: Date
}

export const Title = (props: ITitleProps) => {
  const { index, title, organization, createdAt } = props
   const key = useSelector((state: RootState) => state.appData.timestamp)
  return (
    <TitleContainer>
      <DescriptionBox>
        <IncidentNumber>#{index}</IncidentNumber>
        <OrganizationName>{organization}</OrganizationName>
      </DescriptionBox>
      <TimeBox>
        <IncidentTitle>{title}</IncidentTitle>
        <Time key={key}>{renderPassedTime(createdAt)}</Time>
      </TimeBox>
    </TitleContainer>
  )
}

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 15px 0;
  z-index: 2;
`

const DescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
`

const IncidentNumber = styled.div`
  color: ${colors.white};
  font-family: ${fonts.semiBold};
  font-size: 18px;
  letter-spacing: 0.4px;
`

const OrganizationName = styled.div`
  color: ${colors.white};
  font-size: 16px;
`

const TimeBox = styled.div`
  align-items: flex-end;
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  width: 40%;
`

const IncidentTitle = styled.span`
  font-size: 12px;
  letter-spacing: -0.2px;
  opacity: 0.6;
  text-align: right;
`

const Time = styled.span`
  font-size: 12px;
  letter-spacing: -0.2px;
  text-align: right;
`
