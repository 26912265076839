import React from "react";
import styled from "styled-components/macro";
import { Col, Row } from "reactstrap";
import { IncidentType } from "incident-code-core"

import { colors, strings } from "../../constants"
import {
  Navbar,
  NotificationButton,
  OrganizationsDropdown,
} from "../"

interface IFilterProps {
  isNotificationButtonShown?: boolean
  children: React.ReactNode | React.ReactNode[]
  incidentType: IncidentType
  showNotificationForm?(): void
  handleIncidentType(type: IncidentType): void
}

export const Filters: React.FC<IFilterProps> = (props) => (
  <FilterContainer>
    <FilterTopContainer>
      <Row className="ml-0 mr-0" style={{alignItems: "center"}}>
        <Col>
          <Navbar incidentType={props.incidentType} handleIncidentType={props.handleIncidentType} />
          {props.children}
        </Col>
        <Col className="text-right pb-4 pt-4">
          <NotificationButton showNotificationForm={props.showNotificationForm} />
        </Col>
      </Row>
    </FilterTopContainer>
    <FilterBottomContainer>
      <div className="pt-2 pb-2 mr-4">{strings.organization}</div>
      <OrganizationsDropdown />
    </FilterBottomContainer>
  </FilterContainer>
);

const FilterContainer = styled.div`
  font-size: 15px;
`

const FilterTopContainer = styled.div`
  background: ${colors.darkerGray};
`

const FilterBottomContainer = styled.div`
  background: ${colors.background};
  color: ${colors.white};
  padding: 30px 20px 20px;
  display: flex;
  flex-direction: row;
`