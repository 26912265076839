import React, { useState, Fragment } from 'react';
import styled from "styled-components/macro";

interface ILegendProps {
  isEscort?: boolean
}

export const Legend = (props: ILegendProps) => {
  const [isVisible, setVisible] = useState<boolean>();

  const toggleLegend = () => {
    setVisible(!!!isVisible);
  }

  return <Container className="position-absolute mt-2 mr-2">
    <InfoButton
      className="border border-dark rounded-circle text-center position-absolute"
      onClick={toggleLegend}
    >
      i
    </InfoButton>
    {isVisible &&
      <LegendContainer className="p-2 bg-dark-gray position-absolute rounded">
        <LegendItem className="text-light text-wrap d-flex flex-row">
          <div className="pt-2">
            <i className="icon-circle" style={{ color: '#048f4f', fontSize: "18px"}}></i>
          </div>
          <div className="text-light text-wrap pt-1 pb-1 ml-2">Creation of the tip</div>
        </LegendItem>
        {props.isEscort &&
          <Fragment>
            <LegendItem className="text-light text-wrap d-flex flex-row">
              <div className="pt-2">
                <i className="icon-circle" style={{ color: '#4c39eb', fontSize: "18px" }}></i>
              </div>
              <div className="text-light text-wrap pt-1 pb-1 ml-2">User last position</div>
            </LegendItem>
            <LegendItem className="text-light text-wrap d-flex flex-row">
              <div className="pt-2">
              <i className="icon-circle" style={{color: '#ce0942', fontSize: "18px"}}></i>
              </div>
              <div className="text-light text-wrap pt-1 pb-1 ml-2">User in panic</div>
            </LegendItem>
          </Fragment>
        }
        <LegendItem className="text-light text-wrap d-flex flex-row">
          <div className="pt-2">
            <IconSquare className="bg-dark-black rounded pl-1">
              <i className="icon-chat"></i>
            </IconSquare>
          </div>
          <div className="text-light text-wrap pt-1 pb-1 ml-2">Messages sent</div>
        </LegendItem>
        <LegendItem className="text-light text-wrap d-flex flex-row">
          <div className="pt-2">
            <IconSquare className="bg-dark-black rounded pl-1">
              <i className="icon-play-audio"></i>
            </IconSquare>
          </div>
          <div className="text-light text-wrap pt-1 pb-1 ml-2">Videos sent</div>
        </LegendItem>
        {props.isEscort &&
          <Fragment>
            <LegendItem className="text-light text-wrap d-flex flex-row">
              <div className="pt-2">
                <IconSquare className="bg-dark-black rounded pl-1">
                  <i className="icon-path" style={{ color: '#048f4f' }}></i>
                </IconSquare>
              </div>
              <div className="text-light text-wrap pt-1 pb-1 ml-2">Path escorted with audio</div>
            </LegendItem>
            <LegendItem className="text-light text-wrap d-flex flex-row">
              <div className="pt-2">
                <IconSquare className="bg-dark-black rounded pl-1">
                  <i className="icon-path" style={{ color: '#dc8c09' }}></i>
                </IconSquare>
              </div>
              <div className="text-light text-wrap pt-1 pb-1 ml-2">Path escorted with video</div>
            </LegendItem>
            <LegendItem className="text-light text-wrap d-flex flex-row">
              <div className="pt-2">
                <IconSquare className="bg-dark-black rounded pl-1">
                  <i className="icon-path" style={{ color: '#ce0942' }}></i>
                </IconSquare>
              </div>
              <div className="text-light text-wrap pt-1 pb-1 ml-2">Path in panic mode</div>
            </LegendItem>
          </Fragment>
        }
      </LegendContainer>
    }
  </Container>;
}

const Container = styled.div`
  right: 0;
  z-index: 999;
`

const InfoButton = styled.div`
  width: 25px;
  height: 25px;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px
  right: 0;
  cursor: pointer;
`

const LegendContainer = styled.div`
  right: 30px;
  width: 160px;
`

const LegendItem = styled.div`
  font-size: .8em;
`

const IconSquare = styled.div`
  width: 20px;
  height: 20px;
`