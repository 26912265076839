import React from 'react';
import styled from 'styled-components/macro';
import { IncidentVideo } from 'incident-code-core';
import ReactPlayer from 'react-player';
import { OverlayGradient } from "../../../../components";
import { PlayButton } from './components/PlayButton';

interface IPlayerProps {
  onFinish(): void;
  video: IncidentVideo;
  loop: boolean;
}

export const Player: React.FC<IPlayerProps> = (props) => {
  const [isPlaying, setPlaying] = React.useState<boolean>(true);
  const url = props.video && props.video.url ? props.video.url : '';

  const togglePlay = (): void => {
    setPlaying(!isPlaying);
  };
  return (
    <PlayerContainer>
      <VideoPreview>
        {!url ? null : ( //TODO: Add some loader here
          <ReactPlayer
            url={url}
            onEnded={props.onFinish}
            playing={isPlaying}
            loop={props.loop}
            volume={0}
            width={'95%'}
            height={'95%'}
          />
        )}
        <PlayButton isPlaying={isPlaying} sideSize={55} onClick={togglePlay} />
        {isPlaying || <OverlayGradient />}
      </VideoPreview>
    </PlayerContainer>
  );
};

const PlayerContainer = styled.div`
  width: 50%;
  max-height: 370px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    .player-btn {
      display: block;
    }
  }
`;

const VideoPreview = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  margin-right: 10px;
`
