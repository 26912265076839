import React from 'react'
import { Modal, ModalBody } from 'reactstrap'

interface Props {
  children: React.ReactNode | React.ReactNode[]
  open: boolean
}

export const ActionModal: React.FC<Props> = ({ children, open }) => (
  <Modal isOpen={open}>
    <ModalBody>
      {children}
    </ModalBody>
  </Modal>
)

