import * as React from 'react';
import styled from 'styled-components/macro';
import { colors, strings } from '../../constants';

export const Loader = (props: any) => {
  return(
  <Container><Title>{props.message || strings.loading}</Title></Container>
)};


const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.background};
`;

const Title = styled.h2`
  color: ${colors.white};
`;
