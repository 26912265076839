import React from 'react'
import { useField } from 'formik'
import styled from 'styled-components/macro'

import { colors } from '../../constants'

const Checkbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props })
  return (
    <>
      <Label>
        <input type="checkbox" {...field} {...props} />
        {children}
      </Label>
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </>
  )
}

const Label = styled.label`
  align-items: center;
  color: ${colors.black};
  display: flex;
  font-size: 18px;
  margin-bottom: 5px;

  input {
    margin-right: 10px;
    position: relative;
    top: -2px;
  }
`

export default Checkbox
