export const colors = {
  primary: '#503bff',
  secondary: '#e00543',
  background: '#1f2232',
  gradient_button: ['#a11ce7', '#503bff'],
  ghostWhite: '#fcfcff',
  midnight: '#444964',
  linearGradient: 'linear-gradient(to left, #503bff, #a11ce7)',
  overlayGradientColors: [
    'rgba(25, 27, 35, 0.9)',
    'rgba(25, 27, 35, 0.7)',
    'rgba(25, 27, 35, 0.2)',
    'rgba(25, 27, 35, 0.6)',
  ],
  transparent: 'transparent',
  coriander: '#939ac2',
  black: '#191b23',
  white: '#fff',
  lightGray: '#e3e6f9',
  midGray1: '#686a76',
  midGray2: '#313546',
  midGray3: '#2d303d',
  midGray4: '#0e0f13',
  darkGray: '#1f212a',
  darkerGray: '#16181e',
  videoOverlay: '#121419',
  darkRed: 'rgb(186, 0, 0)',
}
