import React from 'react';
import styled from 'styled-components/macro';

import { colors, images } from '../../constants';

interface IEmailProps {
  email: string;
  index: number;
  inverted: boolean;
  removeEmail(index: number): void;
}

export const Email: React.FC<IEmailProps> = ({
  email, index, inverted, removeEmail
}) => {
  const onRemoveEmail = () => removeEmail(index);

  return (
    <EmailItem inverted={inverted} key={index}>
      {email}
      <img onClick={onRemoveEmail} src={images.close} alt="close" />
    </EmailItem>
  );
};

interface IEmailItem {
  inverted: boolean
}
const EmailItem = styled.p`
  padding: 10px 20px;
  border: 2px solid ${colors.midnight};
  color:  ${(props: IEmailItem) => props.inverted ? `${colors.black}` : `${colors.ghostWhite}`};
  font-size: 18px;
  letter-spacing: -0.38px;
  line-height: 28px;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
