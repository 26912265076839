import styled from "styled-components/macro"
import { Dropdown } from 'reactstrap';
import { colors } from "../../constants"

export const Container = styled.div`
  align-items: center;
  background-color: ${colors.black};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 52px;
  justify-content: space-between;
  padding: 10px 20px;
`

export const Logo = styled.img`
  width: 162px;
  height: 35px;
`

export const AccountArea = styled.div`
  align-items: center;
  display: flex;
`

export const StyledDropdown = styled(Dropdown)`
  .btn {
    color: ${colors.lightGray};
    font-size: 14px;
    outline: 0;

    &:hover {
      color: ${colors.midGray1};
    }

    &:focus {
      box-shadow: none;
    }

    &:after {
      position: relative;
      top: 2px;
    }
  }

  .dropdown-menu {
    background-color: ${colors.black};
    border: 1px solid ${colors.midGray2};
  }

  .dropdown-item {
    color: ${colors.lightGray};
    font-size: 14px;
    outline: 0;
    text-align: right;

    &:hover {
      background: transparent;
      color: ${colors.midGray1};
    }
  }
`
