import React from 'react'
import { FormikProps, ErrorMessage } from 'formik'

import { strings } from '../../constants'
import { AddNoteProps, FormValues } from './interfaces'
import { Wrapper, Form, Textarea, Button, Label, H3, RedMessage, CancelButton } from './styles'

const CloseEscortForm: React.FC<AddNoteProps & FormikProps<FormValues>> = ({
  values,
  status,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  onHideForm
}) => (
  <Wrapper>
    <Form onSubmit={handleSubmit}>
      <H3>
        The user has not yet confirmed that they are safe, are you sure you want to close the
        escort?
      </H3>
      <Label>Please enter below why you are closing the escort manually:</Label>
      <Textarea
        name="comment"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.comment}
        placeholder={strings.incidentDetail.sendMessageForm.placeholder}
        disabled={isSubmitting}
      />
      <ErrorMessage component={RedMessage} name="comment" />
      {status && status.message && <RedMessage>{status.message}</RedMessage>}
      <Button type="submit" disabled={isSubmitting} gradient={true}>
        {strings.incidentDetail.resolvedIncident}
      </Button>
    </Form>
    <CancelButton className="closeModal" onClick={onHideForm} gradient={false}>
      {strings.incidentDetail.buttons.cancel}
    </CancelButton>
  </Wrapper>
)

export default CloseEscortForm
