import * as React from "react"
import styled from "styled-components/macro"
import { colors, strings } from "../../constants"

interface Props {
  comment?: string
}

export const Comment: React.FC<Props> = ({ comment  }) => (
  <Container>
    <Title>{strings.incidentDetail.detailTitles.comment}</Title>
    {comment && <DetailInfo>{comment}</DetailInfo>}
    {!comment && <DetailInfo>{strings.incidentDetail.detailTitles.noComment}</DetailInfo>}
  </Container>
)

const Container = styled.div`
  width: 100%;
`

const Title = styled.h3`
  color: ${colors.midnight};
  font-size: 14px;
  line-height: 1.29;
  margin-bottom: 0.3em;
`

const DetailInfo = styled.div`
  color: ${colors.ghostWhite};
  font-size: 16px;
  margin: 0 0 4px 0;
  overflow-y: auto;
  max-height: 150px;
`
