import * as React from 'react'
import { AppUser } from 'incident-code-core'
import styled from 'styled-components/macro'
import { colors, fonts } from '../../../../constants'
import { USPhoneFormat } from '../../../../utils/formatPhoneNumbers'


interface IInfoProps {
  createdBy: AppUser
  isUnresolved?: boolean
  address?: string
  createdAt?: Date
}

export const Info = (props: IInfoProps) => {
  const {
    createdBy: { firstName, lastName, phone },
    isUnresolved,
    address,
  } = props

  return (
    <InfoContainer>
      {isUnresolved && <UnresolvedMark>New</UnresolvedMark>}
      <CreaterName>
        {firstName} {lastName}
      </CreaterName>
      <CreaterPhone>{USPhoneFormat(phone)}</CreaterPhone>
      <IncidentLocation>{address || ''}</IncidentLocation>
    </InfoContainer>
  )
}

const InfoContainer = styled.div`
  background-color: ${colors.midGray4};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 170px;
  padding: 20px 15px 15px;
  position: relative;
  z-index: 2;
`

const CreaterName = styled.span`
  color: ${colors.white};
  font-family: ${fonts.semiBold};
  font-size: 18px;
`

const CreaterPhone = styled.span`
  color: ${colors.ghostWhite};
  font-family: ${fonts.light};
  font-size: 16px;
  opacity: 0.9;
`

const IncidentLocation = styled.p`
  color: ${colors.ghostWhite};
  font-size: 13px;
  margin-bottom: 0;
  margin-top: auto;
  opacity: 0.4;
`

const UnresolvedMark = styled.div`
  align-items: center;
  background-color: ${colors.secondary};
  border-radius: 16px;
  color: ${colors.ghostWhite};
  display: flex;
  font-size: 12px;
  height: 32px;
  justify-content: center;
  padding: 0 12px;
  position: absolute;
  right: 10px;
  top: -16px;
`
