import * as React from 'react'
import styled from 'styled-components/macro'
import { History } from 'incident-code-core'
import { getCreatedAtString } from '../../utils/dateUtils'

interface IBoxProps {
  message: History
}

export const BoxHistory: React.FC<IBoxProps> = ({ message }) => (
  <Container>
    <BoxMessage>
      <Content>
        <Title>{message.title}</Title>
        {message.content && <Paragraph>{message.content}</Paragraph>}
        <BoxCreatedAt>{getCreatedAtString(message.createdAt)}</BoxCreatedAt>
      </Content>
    </BoxMessage>
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-bottom: 3px;
`
const BoxMessage = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  width: fit-content;
  margin: auto;
  border-radius: 10px;
`
const Content = styled.div`
  flex-direction: row;
  min-width: 200px;
`
const Title = styled.div`
  font-size: 15px;
  justify-content: center;
  text-align: center;
`
const Paragraph = styled.div`
  font-size: 13px;
  justify-content: center;
  text-align: center;
`
const BoxCreatedAt = styled.div`
  font-size: 12px;
  justify-content: center;
  text-align: center;
`
