import { takeLatest, select, put, call, take, takeEvery } from 'redux-saga/effects'
import { appDataActions } from '.'

export default function* appDataRootSaga() {
  while (true) {
    yield call(delay, 5000)
    yield put(appDataActions.setTimestamp(Date.now()))
  }
}

function delay(duration: number) {
  return new Promise(resolve => {
    setTimeout(() => resolve(true), duration)
  })
}
