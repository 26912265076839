import { withFormik } from 'formik';
import * as Yup from 'yup';

import ChatForm from './Form';
import { ChatFormProps, FormValues } from './interfaces';
import { handleSubmit } from './utils'

const chatSchema = Yup.object().shape({
  message: Yup.string().trim().required(),
});

export const Send = withFormik<ChatFormProps, FormValues>({
  mapPropsToValues: () => ({ message: "" }),
  validationSchema: chatSchema,
  handleSubmit: handleSubmit
})(ChatForm);