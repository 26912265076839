import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import styled from 'styled-components/macro'
import { colors, fonts, strings } from '../../../constants'

interface ILoginButtonsProps {
  onLogin(): void
  resetPassword(): void
}

const loginButtons = (props: ILoginButtonsProps & RouteComponentProps) => {
  const { loginButton, resetButton } = strings.loginScreen
  const { onLogin, resetPassword } = props
  return (
    <Container>
      <LoginButton onClick={onLogin}>
        <Text>{loginButton}</Text>
      </LoginButton>
      <ResetButton onClick={resetPassword}>
        <Text>{resetButton}</Text>
      </ResetButton>
    </Container>
  )
}

export const LoginButtons = withRouter(loginButtons)

const Container = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
`

const LoginButton = styled.button`
  background: linear-gradient(104deg, #503bff, #4632ec);
  border-radius: 5px;
  border: none;
  color: white;
  height: 60px;
  margin-bottom: 10px;
  outline: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const ResetButton = styled.button`
  background: ${colors.transparent};
  border: none;
  color: white;
  outline: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  & > span {
    font-size: 16px;
  }
`

const Text = styled.span`
  color: ${colors.ghostWhite};
  font-family: ${fonts.defaultFont};
  font-size: 20px;
  font-weight: normal;
  letter-spacing: -0.1px;
  line-height: 26px;
`
