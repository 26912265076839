import React from 'react'
import moment from 'moment'
import { Row, Col } from 'reactstrap'
import styled from 'styled-components/macro'

import { notificationActions } from '../../store/notifications'
import { useDispatch } from 'react-redux'
import { openNewWindow } from '../../utils/browser-utils'
import { toast } from 'react-toastify'
import { INotification } from '../../store/notifications/adapter'

const { markNotificationAsRead } = notificationActions

const NotificationComponent = ({
  notification,
  popover = true,
  onClick
}: {
  notification: INotification
  popover?: boolean
  onClick?: Function
}) => {
  const dispatch = useDispatch()
  const sentAtDate = moment(notification.createdAt, 'MMMM DD, YYYY h:mm a')
  return (
    <Row
      className={
        !popover &&
        (notification.type.toString() === 'escort' || notification.type.toString() === 'incident')
          ? 'elementToFadeInAndOut'
          : ''
      }
      onClick={() => {
        if (!notification.consumed) {
          dispatch(
            markNotificationAsRead({
              id: notification.id
            })
          )
        }
        if (toast.isActive(notification.incidentId)) {
          toast.dismiss(notification.incidentId)
        }
        openNewWindow(notification.link)
        onClick && onClick()
      }}
      style={{ cursor: 'pointer' }}
    >
      <Col>
        {notification.type === 'panic' && !popover ? (
          <>
            <PanicLabel>ATTENTION</PanicLabel>
            <Title consumed={notification.consumed && popover}>{notification.title}</Title>
            <PanicLabel>See Now</PanicLabel>
          </>
        ) : (
          <Title consumed={notification.consumed && popover}>{notification.title}</Title>
        )}

        {popover && (
          <>
            <Date>{sentAtDate.format('MMM DD, h:mm a')}</Date>
            <TimeFromNow>{sentAtDate.fromNow()}</TimeFromNow>
          </>
        )}
      </Col>
    </Row>
  )
}

interface IStyleProps {
  consumed: boolean
}

const Title = styled.div<IStyleProps>`
  width: 230px;
  font-weight: ${props => (props.consumed ? 'normal' : 'bold')};
`

const PanicLabel = styled.span`
  width: 185px;
  display: block;
  text-align: center;
  background-color: #d21515;
  padding-top: 2px;
  margin: 15px 0px;
`

const Date = styled.span`
  font-size: 13px;
  line-height: 1.17;
  letter-spacing: -0.2px;
  display: block;
`

const TimeFromNow = styled.span({
  fontSize: '12px',
  lineHeight: '1.17',
  letterSpacing: '-0.2px',
  display: 'block',
  marginBottom: '10px',
  '::first-letter': {
    textTransform: 'capitalize'
  }
})

export default NotificationComponent
