import firebase from 'firebase/app';
import 'firebase/messaging';

const initFirebase = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp({
      apiKey: "AIzaSyAYxnt3gh7NsC-ehaul-PaN0ZJGm2NxQdI",
      authDomain: "api-4848415948144842116-428971.firebaseapp.com",
      databaseURL: "https://api-4848415948144842116-428971.firebaseio.com",
      projectId: "api-4848415948144842116-428971",
      storageBucket: "api-4848415948144842116-428971.appspot.com",
      messagingSenderId: "562639745737",
      appId: "1:562639745737:web:63e0718b90035ed1f0d318"
    })
  }

  const messaging = firebase.messaging()

  return messaging.requestPermission().then(() => messaging.getToken())
}

export default initFirebase
