import {
  ChatMessage,
  WallIncident,
  WallHistoryRequest,
  HistoryType,
  ChatActionType,
  History
} from "incident-code-core"
import React, { SetStateAction } from "react"
import styled from "styled-components/macro"

import {
  ChatEventHandler,
  chatService
} from "../../services/chat-service"

import {
  historyService
} from "../../services/history-service"

import { Box } from './Box'
import { Send } from './ChatMessage'
import { BoxHistory } from './BoxHistory'

interface Props {
  incident: WallIncident
  isClosed: boolean
  setChatChannel?: SetStateAction<any>
}

interface State {
  messages: any[],
  twilioMessages: ChatMessage[],
  histories: History[],
  intervalHistory: number,
}

export class Chat extends React.Component<Props, State> implements ChatEventHandler {
  private messagesEndRef: React.RefObject<HTMLInputElement>;

  constructor(props: any) {
    super(props)
    this.state = {
      messages: [],
      twilioMessages: [],
      intervalHistory: null,
      histories: [],
    }
    this.messagesEndRef = React.createRef()
  }

  async componentDidMount(): Promise<void> {
    const { incident } = this.props;
    let intervalHistory = null;

    await chatService.init(incident)
    await chatService.initChat(this, this.props.setChatChannel)
    const twilioMessages = await chatService.loadMessages()

    this.fetchHistory().then(() => this.reorder());

    if (incident.isResolved === false) {
      intervalHistory = setInterval(async () => {
        this.fetchHistory().then(() => this.reorder());
      }, 7000);
    } else {
      this.fetchHistory().then(() => this.reorder());
    }

    this.setState({ twilioMessages, intervalHistory }, () => this.reorder())
  }

  async componentWillUnmount(): Promise<void> {
    const { intervalHistory } = this.state
    clearInterval(intervalHistory)
    await chatService.close()
  }

  async componentDidUpdate(prevProps: Props, prevState: State): Promise<void> {
    const { messages } = this.state
    if (prevState.messages.length < messages.length) {
      this.scrollToBottom()
    }
  }

  fetchHistory = async (): Promise<void> => {
    const { incident: { id } } = this.props;

    try {
      const response = await historyService.getHistoryByIncidentId(id)
      const { data: { history: histories } } = response.data

      this.setState({ histories })
    } catch (error) {
      const { intervalHistory } = this.state
      clearInterval(intervalHistory)
    }
  }

  onSend = (message: string) => chatService.send(message)

  onMessageAdded = (message: ChatMessage) => this.setState(previousState => ({
    twilioMessages: previousState.twilioMessages.concat(message)
  }), () => this.reorder())

  onJoined = () => {
    const { incident: { id, isResolved }} = this.props;
    if (isResolved) return

    const payload: WallHistoryRequest = {
      incidentId: `${id}`,
      type: HistoryType.Chat,
      action: ChatActionType.Joined
    }
    historyService.create(payload)
    this.props.setChatChannel && this.props.setChatChannel(chatService.channel)
    window.addEventListener("beforeunload", () => {
      chatService.leave()
    })
  }

  onConnectionError = () => {}

  onConnectionStateChanged = () => {}

  onMemberJoined = () => {}

  reorder = () => {
    const { twilioMessages, histories } = this.state;
    const concatMessages: any[] = [...twilioMessages, ...histories]
    const messages = concatMessages.sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();
      return dateA > dateB ? 1 : -1;
    })
    this.setState({ messages })
  }

  scrollToBottom = () => {
    this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  render(): React.ReactNode {
    const { messages } = this.state;
    const { incident: { organization, isResolved } } = this.props;

    return (
      <Container>
        <ChatContainer>
          <Body>
            <Content>
              {messages.map((message) => {
                if (message.hasOwnProperty('from')) {
                  if (message.from && message.from.split(":")[1] === organization) {
                    return (<Box key={message.id} right={true} message={message} />)
                  }
                  return (<Box key={message.id} right={false} message={message} />)
                }
                return (<BoxHistory key={message.id} message={message} />)
              })}
              <div ref={this.messagesEndRef} />
            </Content>
          </Body>
          <Footer>
            <Actions>
              <Send isResolved={isResolved} onSend={this.onSend} />
            </Actions>
          </Footer>
        </ChatContainer>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 50%;
`
const ChatContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
`
const Body = styled.div`
  display: flex;
  flex: 1 1 85%;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: white;
`
const Content = styled.div`
  height: 150px;
  flex-grow: 1;
  resize: vertical;
  overflow-y: scroll;
`
const Footer = styled.div`
  border: 1px solid pink;
  display: flex;
  min-height: 3em;
  max-height: 3em;
`
const Actions = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
