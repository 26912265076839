export const INCIDENT_SCROLL_CONTAINER = 'INCIDENT_SCROLL_CONTAINER';

export const INCIDENT_SCROLL_PARAMS = {
  duration: 1500,
  smooth: true,
  containerId: INCIDENT_SCROLL_CONTAINER,
};

export const SCROLL_CHILD_CLASS = "scrollItem";
export const ITEM_MARGIN_RIGHT = 8;
export const ITEM_MARGIN_BOTTOM = 10;
export const SCROLL_CONTAINER_PADDING = 20;