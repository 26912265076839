import axios, { AxiosError, AxiosInstance, AxiosResponse, Method } from 'axios';
import { Id, urlUtils } from "incident-code-core"
import { EventEmitter } from "events"
import { appSetting, KeyToken } from "./app-setting"
import { Env } from "../constants/env"

export const ApiListener = new EventEmitter()
export const TokenExpiredEvent = "tokenExpired"

enum RequestTypes {
  GET = "get",
  POST = "post",
  PATCH = "patch",
  DELETE = "delete"
}

type THeaders = {
  [key: string]: string
}

export class ApiService {
  baseUrl = Env.apiUrl
  protected basePaths: string[]

  api: AxiosInstance = axios.create({
    baseURL: this.baseUrl
  })

  constructor() {
    this.api.interceptors.response.use(null, (error: AxiosError) => {
      if (error.response?.status === 401 || error.response?.status === 403) {
        ApiListener.emit(TokenExpiredEvent, error)
      }
      return Promise.reject(error)
    })
  }

  private async request<T = any>(
    method: Method,
    path: (string | Id)[],
    data?: any,
    query?: any
  ): Promise<T> {
    const url = this.generateUrl(path, query)
    const headers = this.generateHeaders()
    return this.api.request({ method, url, headers, data })
  }

  private generateUrl = (paths: (string | Id)[], query: any) => {
    let p = [this.baseUrl]

    if (this.basePaths && this.basePaths.length) p.push(...this.basePaths)

    if (paths && paths.length) p.push(...(paths as string[]))

    return urlUtils.generate(p, query)
  }

  private generateHeaders = () => {
    let headers: THeaders = {
      "Content-Type": "application/json",
      "Security-Code": Env.apiSecurityCode,
      "Client-Version": Env.appVersion
    }

    if (appSetting.isLoggedIn) {
      const token = appSetting.getValue(KeyToken)
      headers = {
        ...headers,
        Authorization: `${token.token_type} ${token.access_token}`
      }
    }

    return headers
  }

  protected async get<T = any>(path: (string | Id)[], query?: any): Promise<AxiosResponse<T>> {
    return await this.request(RequestTypes.GET, path, null, query)
  }

  protected async post<T = any>(path: string[], data?: any): Promise<AxiosResponse<T>> {
    return await this.request(RequestTypes.POST, path, data, null)
  }

  protected async patch<T = any>(path: string[], data?: any): Promise<AxiosResponse<T>> {
    return await this.request(RequestTypes.PATCH, path, data, null)
  }

  protected async delete<T = any>(path: string[], data?: any): Promise<AxiosResponse<T>> {
    return await this.request(RequestTypes.DELETE, path, data, null)
  }
}
