import React from 'react'

import { strings } from "../../constants"
import { TabsContainer, TabsButton } from './styles'

interface IStatusProps {
  showClosed: boolean,
  setShowClosed(showClosedIncidents: boolean): void
}

export const StatusTab = ({ showClosed, setShowClosed }: IStatusProps) => (
  <TabsContainer>
    <TabsButton
      className="toggleToActive"
      onClick={() => setShowClosed(false)}
      active={!showClosed}
    >
      {strings.active}
    </TabsButton>
    <TabsButton
      className="toggleToClose"
      onClick={() => setShowClosed(true)}
      active={showClosed}
    >
      {strings.closed}
    </TabsButton>
  </TabsContainer>
)